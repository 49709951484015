import React, { useState } from 'react';
import { Check } from 'lucide-react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from '../services/api';

const PricingCard = ({ plan, isAnnual, handleSubscribe, isPopular }) => {
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <motion.div
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.5 }}
      className={`relative bg-white dark:bg-gray-800 rounded-2xl shadow-xl ${
        isPopular ? 'border-2 border-red-500 scale-105' : 'border border-gray-200 dark:border-gray-700'
      }`}
    >
      {isPopular && (
        <div className="absolute -top-5 left-1/2 transform -translate-x-1/2">
          <span className="bg-red-500 text-white px-4 py-1 rounded-full text-sm font-semibold">
            Most Popular
          </span>
        </div>
      )}

      <div className="p-8">
        <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
          {plan.name}
        </h3>

        <div className="mb-6">
          {plan.name === 'Trial' ? (
            <div>
              <p className="text-gray-600 dark:text-gray-400 mt-2">{plan.credits}</p>
              <p className="text-sm text-gray-500">{plan.duration}</p>
            </div>
          ) : plan.name === 'Enterprise' ? (
            <div>
              <span className="text-4xl font-bold text-gray-900 dark:text-white">Contact Us</span>
              <p className="text-gray-600 dark:text-gray-400 mt-2">{plan.credits}</p>
              <p className="text-sm text-green-500">{plan.creditNote}</p>
            </div>
          ) : (
            <div>
              <span className="text-4xl font-bold text-gray-900 dark:text-white">
                {isAnnual ? plan.annualPrice : plan.monthlyPrice}
              </span>
              <span className="text-gray-600 dark:text-gray-400 ml-2">
                {isAnnual ? '/ year' : '/ month'}
              </span>
              {isAnnual && plan.savings && (
                <p className="text-green-500 text-sm mt-1">Save {plan.savings} yearly</p>
              )}
              <p className="text-gray-600 dark:text-gray-400 mt-2">{plan.credits}</p>
            </div>
          )}
        </div>

        <p className="text-gray-600 dark:text-gray-400 mb-6">
          {plan.description}
        </p>

        <ul className="space-y-4 mb-8">
          {plan.features.map((feature, index) => (
            <li key={index} className="flex items-start gap-3">
              <Check className="h-5 w-5 text-green-500 flex-shrink-0 mt-0.5" />
              <span className="text-gray-600 dark:text-gray-400">{feature}</span>
            </li>
          ))}
        </ul>

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => handleSubscribe(plan.name)}
          className={`w-full py-3 px-4 rounded-lg font-semibold transition-colors ${
            isPopular
              ? 'bg-red-500 hover:bg-red-600 text-white'
              : 'bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-900 dark:text-white'
          }`}
        >
          {plan.name === 'Enterprise' ? 'Contact Sales' : 'Get Started'}
        </motion.button>
      </div>
    </motion.div>
  );
};

const PricingPage = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [isAnnual, setIsAnnual] = useState(false);

  const plans = [
    {
      name: 'Trial',
      credits: '10,000 AI Credits',
      duration: 'Expires in 3 days',
      description: 'Try our service for free with limited features',
      features: [
        'Access to basic case law database',
        'Up to 5 document uploads',
        'Standard response speed',
        'Email support',
      ],
    },
    {
      name: 'Essential',
      monthlyPrice: '₦30,000',
      annualPrice: '₦300,000',
      savings: '₦60,000',
      credits: '800,000 AI Credits/month',
      description: 'Limited usage for brief queries and smaller research tasks',
      features: [
        'Basic access to case law database',
        'Up to 25 document analysis/month',
        'Standard response speed',
        'Chat and email support',
      ],
      buttonVariant: 'outline',
    },
    {
      name: 'Professional',
      monthlyPrice: '₦50,000',
      annualPrice: '₦500,000',
      savings: '₦100,000',
      credits: '2,000,000 AI Credits/month',
      description: 'For users with moderate usage needs',
      features: [
        'Full access to case law and legal databases',
        'Up to 100 document analysis/month',
        'Faster response speed with priority support',
        'Advanced legal reasoning and query refinement tools',
        'Chat and email support',
      ],
      buttonVariant: 'default',
      popular: true,
    },
    {
      name: 'Enterprise',
      description: 'Suitable for high-demand legal work and team collaboration',
      credits: 'Pay as you go credits',
      creditNote: 'No expiration on credits',
      features: [
        'Unlimited access to all databases and resources',
        'Unlimited document uploads',
        'Priority response speed and support',
        'Admin credit distribution to team members',
        'Team collaboration for up to 4 members',
        'Dedicated account manager',
      ],
      buttonVariant: 'outline',
    },
  ];

  const handleSubscribe = async (planName) => {
    if (!token) {
      navigate('/login');
      return;
    }

    let credits;
    let creditsExpires;

    switch (planName) {
      case 'Trial':
        credits = 10000;
        creditsExpires = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000); // 3 days
        break;
      case 'Essential':
        credits = 800000;
        creditsExpires = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000); // 30 days
        break;
      case 'Professional':
        credits = 2000000;
        creditsExpires = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000); // 30 days
        break;
      case 'Enterprise':
        credits = 0; // Pay as you go
        creditsExpires = null; // No expiration
        break;
      default:
        credits = 0;
        creditsExpires = null;
    }

    try {
      await api.post('/api/user/update-credits', {
        credits,
        creditsExpires
      });

      navigate('/signup', { state: { selectedPlan: planName } });
    } catch (error) {
      console.error('Error updating credits:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Simple, Transparent Pricing
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-400">
            Choose the plan that best fits your needs
          </p>
        </motion.div>

        <div className="flex items-center justify-center gap-4 mb-12">
          <span className={`text-lg ${!isAnnual ? 'text-blue-500 font-semibold' : 'text-gray-600 dark:text-gray-400'}`}>
            Monthly
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={isAnnual}
              onChange={() => setIsAnnual(!isAnnual)}
            />
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-red-500"
            ></motion.div>
          </label>
          <span className={`text-lg ${isAnnual ? 'text-blue-500 font-semibold' : 'text-gray-600 dark:text-gray-400'}`}>
            Annual
          </span>
          {isAnnual && (
            <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
              Save up to 20%
            </span>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {plans.map((plan, index) => (
            <PricingCard
              key={plan.name}
              plan={plan}
              isAnnual={isAnnual}
              handleSubscribe={handleSubscribe}
              isPopular={plan.name === 'Professional'}
            />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="mt-16 text-center"
        >
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Need a Custom Solution?
          </h2>
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            Contact our sales team for custom enterprise solutions tailored to your organization's needs.
          </p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/contact')}
            className="bg-red-500 hover:bg-red-600 text-white px-8 py-3 rounded-lg font-semibold transition-colors"
          >
            Contact Sales
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
};

export default PricingPage;