import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, updateCredits } from '../store/authSlice.js';
import { fetchConversations, updateConversation } from '../store/conversationsSlice.js';
import { Send, FileText, Trash2, LogOut, PlusCircle, UserCircle, Crown, Paperclip, X, Briefcase, ChevronRight, ChevronLeft } from 'lucide-react';
import { useTheme } from '../providers/ThemeProvider';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import FeedbackModal from './FeedbackModal';
import api from '../services/api.js';
import MarkdownMessage from './MarkdownMessage';
import { Avatar, FilePreviewBadge, AttachmentPreview } from './chatmodules';
import { AnimatedEllipsis } from './chatmodules';
import MessageActions from './chatmodules/MessageActions';
import Logo from './Logo';
import TemplateMessages from './chatmodules/TemplateMessages';
import ChatHeader from './chatmodules/ChatHeader';
import ChatSidebar from './chatmodules/ChatSidebar';
import FileUploadButton from './chatmodules/FileUploadButton';
import { SUBSCRIPTION_STATUS } from '../constants/subscriptionStatuses';
import SubscriptionExpiredBanner from './SubscriptionExpiredBanner';
import VoiceInputButton from './chatmodules/VoiceInputButton';

const BetaBadge = () => (
  <span className="inline-flex items-center justify-center px-2 py-0.5 ml-2 text-xs font-medium text-white bg-blue-500 rounded-full">
    BETA
  </span>
);

const Chat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, token, credits } = useSelector(state => state.auth);
  const { conversations, status: conversationsStatus } = useSelector(state => state.conversations);

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [streamingResponse, setStreamingResponse] = useState('');
  const [isThinking, setIsThinking] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [messageFeedback, setMessageFeedback] = useState({});
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const chatAreaRef = useRef(null);

  const { isDarkMode, toggleTheme } = useTheme();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [conversationToDelete, setConversationToDelete] = useState(null);
  const [isDesktopSidebarCollapsed, setIsDesktopSidebarCollapsed] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState(new Set(['Today']));
  const [isMultiDeleteMode, setIsMultiDeleteMode] = useState(false);
  const [selectedConversations, setSelectedConversations] = useState(new Set());

  const canUploadFiles = useCallback(() => {
    const userPlan = user?.plan?.type?.toLowerCase() || 'trial';
    return !['trial', 'essential'].includes(userPlan);
  }, [user?.plan?.type]);

  const isSubscriptionValid = useCallback(() => {
    if (!user?.plan) return false;
    
    const isActive = user.plan.status === SUBSCRIPTION_STATUS.ACTIVE;
    const isTrial = user.plan.status === SUBSCRIPTION_STATUS.TRIAL;
    
    if (!isActive && !isTrial) return false;
    
    // Check expiration if exists
    if (user.plan.expiresAt) {
      return new Date(user.plan.expiresAt) > new Date();
    }
    
    return true;
  }, [user?.plan]);

  useEffect(() => {
    if (!user || !token) {
      navigate('/login');
    }
  }, [user, token, navigate]);

  // Add URL handling when conversation changes
  useEffect(() => {
    if (currentConversationId) {
      window.history.pushState({}, '', `/chat/${currentConversationId}`);
    } else {
      window.history.pushState({}, '', '/chat');
    }
  }, [currentConversationId]);

  // Handle initial load from URL
  useEffect(() => {
    const path = window.location.pathname;
    const match = path.match(/\/chat\/(.+)/);
    if (match && match[1]) {
      const conversationId = match[1];
      setCurrentConversationId(conversationId);
    }
  }, []);

  const loadConversation = useCallback(async (conversationId) => {
    console.log('loadConversation called with:', conversationId);
    if (!conversationId) {
      console.log('No conversation ID, resetting state');
      setMessages([]);
      setCurrentConversationId(null);
      window.history.pushState({}, '', '/chat');
      return;
    }
    
    try {
      const response = await api.get(`/api/conversations/${conversationId}`);
      console.log('Loaded conversation:', response.data);
      
      if (response.data && response.data.messages) {
        const uniqueMessages = removeDuplicateMessages(response.data.messages);
        setMessages(uniqueMessages);
        setCurrentConversationId(conversationId);
        setStreamingResponse('');
        window.history.pushState({}, '', `/chat/${conversationId}`);
      }
    } catch (error) {
      console.error('Error loading conversation:', error);
      if (error.response?.status === 404) {
        setMessages([]);
        setCurrentConversationId(null);
        window.history.pushState({}, '', '/chat');
        dispatch(fetchConversations());
      } else {
        toast.error('Error loading conversation');
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (user && conversationsStatus === 'idle') {
      dispatch(fetchConversations());
    }
  }, [dispatch, user, conversationsStatus]);

  useEffect(() => {
    if (currentConversationId) {
      loadConversation(currentConversationId);
    }
  }, [currentConversationId, loadConversation]);

  const handleSendMessage = useCallback(async (e, customMessage = null) => {
    if (e) e.preventDefault();
    
    // Check subscription status before sending message
    if (!isSubscriptionValid()) {
      toast.error('Your subscription has expired. Please renew to continue.');
      return;
    }
    
    const messageToSend = customMessage || inputMessage.trim();
    if (!messageToSend && !selectedFile) return;

    const activeConversationId = currentConversationId;
    console.log('Starting message send with conversationId:', activeConversationId);

    const newUserMessage = {
      role: 'user',
      content: messageToSend,
      ...(selectedFile && { attachment: selectedFile })
    };
    
    setMessages(prevMessages => [...prevMessages, newUserMessage]);
    setInputMessage('');
    setIsLoading(true);
    setIsThinking(true);

    try {
      const response = await fetch(`${api.defaults.baseURL}/api/chat`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: messageToSend,
          conversationId: activeConversationId || '',
          processedContent: selectedFile?.processedContent || null
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message || 'Unknown error'}`);
      }
      
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      // Move accumulatedResponse into a ref to avoid closure issues
      const responseRef = { current: '' };
      setIsThinking(false);
      
      // Initialize the assistant message immediately
      setMessages(prevMessages => [
        ...prevMessages,
        { role: 'assistant', content: '', isStreaming: true }
      ]);

      let buffer = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;

        // Process complete messages from buffer
        const lines = buffer.split('\n');
        buffer = lines.pop() || ''; // Keep incomplete line in buffer

        for (const line of lines) {
          if (line.trim() && line.startsWith('data: ')) {
            try {
              // Skip parsing if it's the [DONE] message
              if (line.includes('[DONE]')) continue;

              const data = JSON.parse(line.slice(5));

              if (data.type === 'credits_update') {
                dispatch(updateCredits(data.credits));
              } else if (data.chunk) {
                // Update accumulated response in ref
                responseRef.current += data.chunk;
                
                // Update messages state with the current accumulated response
                setMessages(prevMessages => {
                  const updated = [...prevMessages];
                  const lastMessage = updated[updated.length - 1];
                  if (lastMessage?.isStreaming) {
                    return updated.map(msg => 
                      msg.isStreaming 
                        ? {
                            ...msg,
                            content: responseRef.current,
                            ...(newUserMessage.attachment && { attachment: newUserMessage.attachment })
                          }
                        : msg
                    );
                  }
                  return updated;
                });
              }
            } catch (error) {
              if (!line.includes('[DONE]')) {
                console.error('Error parsing chunk:', error);
              }
            }
          }
        }
      }

      // Process any remaining buffer content
      if (buffer.trim()) {
        try {
          const data = JSON.parse(buffer.slice(5));
          if (data.chunk) {
            responseRef.current += data.chunk;
            setMessages(prevMessages => {
              const updated = [...prevMessages];
              const lastMessage = updated[updated.length - 1];
              if (lastMessage?.isStreaming) {
                return updated.map(msg => 
                  msg.isStreaming 
                    ? { ...msg, content: responseRef.current }
                    : msg
                );
              }
              return updated;
            });
          }
        } catch (error) {
          console.error('Error parsing final buffer:', error);
        }
      }

      // Update conversation handling
      if (!activeConversationId && responseRef.current) {
        const latestResponse = await api.get('/api/conversations?page=1&limit=1');
        if (latestResponse.data.conversations && latestResponse.data.conversations.length > 0) {
          const newConversation = latestResponse.data.conversations[0];
          console.log('New conversation created:', newConversation);
          
          setCurrentConversationId(newConversation._id);
          window.history.pushState({}, '', `/chat/${newConversation._id}`);
          
          dispatch(fetchConversations());
        }
      } else if (activeConversationId) {
        // If we're in an existing conversation, update it
        await dispatch(updateConversation({ 
          conversationId: activeConversationId,
          messages: [...messages, newUserMessage, { 
            role: 'assistant', 
            content: responseRef.current 
          }]
        }));
      }

    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Error sending message. Please try again.');
    } finally {
      setIsLoading(false);
      setIsThinking(false);
      setSelectedFile(null);
    }
  }, [inputMessage, selectedFile, currentConversationId, token, dispatch, messages, isSubscriptionValid]);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const removeDuplicateMessages = (messages) => {
    return messages.filter((message, index, self) =>
      index === self.findIndex((t) => t.role === message.role && t.content === message.content)
    );
  };

  const deleteConversation = async (conversationId, e) => {
    e.stopPropagation();
    setConversationToDelete(conversationId);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!conversationToDelete) return;

    // Find the conversation element and add red background
    const conversationElement = document.querySelector(`div[data-conversation-id="${conversationToDelete}"]`);
    if (conversationElement) {
      conversationElement.style.backgroundColor = 'rgb(239, 68, 68)';
      conversationElement.style.transition = 'background-color 0.3s ease';
    }

    try {
      await api.delete(`/api/conversations/${conversationToDelete}`);
      dispatch(fetchConversations());
      if (currentConversationId === conversationToDelete) {
        setMessages([]);
        setCurrentConversationId(null);
      }
      toast.success('Conversation deleted successfully');
    } catch (error) {
      console.error('Error deleting conversation:', error);
      toast.error('Failed to delete conversation');
      if (conversationElement) {
        conversationElement.style.backgroundColor = '';
      }
    } finally {
      setIsDeleteModalOpen(false);
      setConversationToDelete(null);
    }
  };

  useEffect(() => {
    console.log('Chat state updated:', {
      messages: messages.map(m => ({ role: m.role, content: m.content.substring(0, 50) + '...' })),
      currentConversationId,
      streamingResponse: streamingResponse.substring(0, 50) + '...',
      isThinking,
      isSidebarOpen,
      isLoading
    });

    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
    }
  }, [messages, currentConversationId, streamingResponse, isThinking, isSidebarOpen, isLoading]);

  useEffect(() => {
    console.log('Messages state updated:', messages);
  }, [messages]);

  const groupConversations = useCallback((conversations) => {
    const now = new Date();
    const groups = [
      { title: 'Today', conversations: [] },
      { title: 'Yesterday', conversations: [] },
      { title: 'Last 7 Days', conversations: [] },
      { title: 'Last 30 Days', conversations: [] },
      { title: 'Older', conversations: [] }
    ];

    conversations.forEach(conv => {
      const convDate = new Date(conv.updatedAt || conv.createdAt);
      const diffDays = Math.floor((now - convDate) / (1000 * 60 * 60 * 24));

      if (diffDays === 0) {
        groups[0].conversations.push(conv);
      } else if (diffDays === 1) {
        groups[1].conversations.push(conv);
      } else if (diffDays < 7) {
        groups[2].conversations.push(conv);
      } else if (diffDays < 30) {
        groups[3].conversations.push(conv);
      } else {
        groups[4].conversations.push(conv);
      }
    });

    return groups.filter(group => group.conversations.length > 0);
  }, []);

  const userConversations = conversations.filter(conv => conv.userId === user?._id);

  // Update the handleKeyPress function
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      // Only handle send if not uploading
      if (!isUploading) {
        handleSendMessage(e);
      } else {
        // Optionally show a toast notification
        toast.warning('Please wait for file upload to complete');
      }
    }
  };

  const handleFeedback = async (index, isHelpful) => {
    // Store the feedback type first without sending to API
    setMessageFeedback(prev => ({
      ...prev,
      [index]: isHelpful
    }));
    
    // Set the selected message index
    setSelectedMessageIndex(index);
    
    // If not helpful, show modal immediately
    // If helpful, show modal for positive feedback
    setIsFeedbackModalOpen(true);
  };

  const handleDetailedFeedback = async (feedback, category = 'other') => {
    try {
      if (!currentConversationId || selectedMessageIndex === null) {
        console.error('Missing conversation ID or message index:', {
          conversationId: currentConversationId,
          messageIndex: selectedMessageIndex
        });
        return;
      }

      const feedbackData = {
        isHelpful: messageFeedback[selectedMessageIndex],
        details: feedback,
        category,
        submittedAt: new Date().toISOString()
      };

      const response = await api.post(
        `/api/conversations/${currentConversationId}/messages/${selectedMessageIndex}/feedback`,
        feedbackData,
        {
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.success) {
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages];
          const targetMessage = updatedMessages[selectedMessageIndex];
          if (targetMessage && targetMessage.role === 'assistant') {
            targetMessage.feedback = feedbackData;
          }
          return updatedMessages;
        });

        toast.success('Thank you for your feedback!', {
          duration: 2000,
          position: 'bottom-center',
          style: {
            background: isDarkMode ? '#374151' : '#ffffff',
            color: isDarkMode ? '#ffffff' : '#000000',
          },
        });
        setIsFeedbackModalOpen(false);
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error('Failed to submit feedback. Please try again.');
    }
  };

  // Add click handler for overlay
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsSidebarOpen(false);
    }
  };

  // Add new function for multi-delete
  const handleMultiDelete = async () => {
    if (selectedConversations.size === 0) return;

    try {
      // Delete all selected conversations
      await Promise.all(
        Array.from(selectedConversations).map(id =>
          api.delete(`/api/conversations/${id}`)
        )
      );

      // Refresh conversations list
      dispatch(fetchConversations());

      // Clear current conversation if it was deleted
      if (selectedConversations.has(currentConversationId)) {
        setMessages([]);
        setCurrentConversationId(null);
      }

      // Reset selection mode
      setIsMultiDeleteMode(false);
      setSelectedConversations(new Set());

      toast.success(`Successfully deleted ${selectedConversations.size} conversations`);
    } catch (error) {
      console.error('Error deleting conversations:', error);
      toast.error('Failed to delete some conversations');
    }
  };

  // Add this useEffect to monitor conversation state changes
  useEffect(() => {
    console.log('Conversation state changed:', {
      currentConversationId,
      messagesCount: messages.length,
      messages: messages.map(m => ({ role: m.role, content: m.content.substring(0, 50) }))
    });
  }, [currentConversationId, messages]);

  // Add popstate handler for browser back/forward buttons
  useEffect(() => {
    const handlePopState = () => {
      const path = window.location.pathname;
      const match = path.match(/\/chat\/(.+)/);
      if (match && match[1]) {
        setCurrentConversationId(match[1]);
      } else {
        setCurrentConversationId(null);
        setMessages([]);
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  // Add handler for voice input
  const handleVoiceInput = useCallback((transcript) => {
    setInputMessage((prev) => prev + ' ' + transcript);
  }, []);

  return (
    <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
      <div><Toaster/></div>
      
      {/* Check subscription status */}
      {!isSubscriptionValid() ? (
        <SubscriptionExpiredBanner planType={user?.plan?.type} />
      ) : (
        <>
          {/* Overlay for mobile - only shows when sidebar is open */}
          {isSidebarOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
              onClick={handleOverlayClick}
            />
          )}
          
          {/* Replace old sidebar with new ChatSidebar component */}
          <ChatSidebar
            isDesktopSidebarCollapsed={isDesktopSidebarCollapsed}
            setIsDesktopSidebarCollapsed={setIsDesktopSidebarCollapsed}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            isMultiDeleteMode={isMultiDeleteMode}
            setIsMultiDeleteMode={setIsMultiDeleteMode}
            selectedConversations={selectedConversations}
            setSelectedConversations={setSelectedConversations}
            handleMultiDelete={handleMultiDelete}
            userConversations={userConversations}
            currentConversationId={currentConversationId}
            loadConversation={loadConversation}
            expandedGroups={expandedGroups}
            setExpandedGroups={setExpandedGroups}
            groupConversations={groupConversations}
            handleLogout={handleLogout}
            user={user}
            navigate={navigate}
          />

          {/* Main Content */}
          <div className="flex-1 flex flex-col">
            <ChatHeader 
              credits={credits}
              isDarkMode={isDarkMode}
              toggleTheme={toggleTheme}
              setIsSidebarOpen={setIsSidebarOpen}
            />
            
            {/* Chat Area */}
            <div 
              className="flex-1 overflow-y-auto p-4 bg-gray-100 dark:bg-gray-900 scrollbar-thin scrollbar-thumb-gray-600 dark:scrollbar-thumb-gray-400 scrollbar-track-transparent hover:scrollbar-thumb-gray-500 dark:hover:scrollbar-thumb-gray-300" 
              ref={chatAreaRef}
            >
              {messages.length === 0 ? (
                <TemplateMessages onSelectTemplate={(template) => setInputMessage(template)} />
              ) : (
                <div className="space-y-4">
                  {messages.map((msg, index) => (
                    <div
                      key={`${msg.role}-${index}`}
                      className={`flex items-start space-x-2 ${msg.role === 'user' ? 'flex-row-reverse space-x-reverse' : 'flex-row'}`}
                    >
                      <Avatar role={msg.role} username={user?.username} />
                      <div
                        className={`max-w-[85%] sm:max-w-[80%] md:max-w-[75%] rounded-lg p-3 sm:p-4 ${
                          msg.role === 'user'
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-200 dark:bg-gray-700'
                        }`}
                      >
                        <div className="flex items-center">
                          <p className="text-sm font-medium text-gray-900 dark:text-white">
                            {msg.role === 'user' ? 'You' : 'ModulawAI'}
                            {msg.role === 'assistant' && <BetaBadge />}
                          </p>
                        </div>
                        <div className={`${
                          msg.role === 'assistant' 
                            ? 'whitespace-normal text-gray-900 dark:text-white' 
                            : 'whitespace-pre-wrap'
                        }`}>
                          {msg.role === 'assistant' ? (
                            <MarkdownMessage 
                              content={msg.content} 
                              isDarkMode={isDarkMode}
                            />
                          ) : (
                            <>
                              {msg.content}
                              {msg.attachment && <AttachmentPreview attachment={msg.attachment} />}
                            </>
                          )}
                        </div>
                        
                        {/* Add feedback controls for assistant messages */}
                        {msg.role === 'assistant' && (
                          <MessageActions 
                            content={msg.content}
                            isDarkMode={isDarkMode}
                            messageFeedback={messageFeedback}
                            index={index}
                            onFeedback={handleFeedback}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                  {isThinking && (
                    <div className="flex items-start space-x-2">
                      <Avatar role="assistant" />
                      <div className="max-w-[80%] bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg p-4">
                        <p className="text-sm font-semibold mb-1">ModulawAI</p>
                        <p>
                          <AnimatedEllipsis />
                        </p>
                      </div>
                    </div>
                  )}
                  {isLoading && !isThinking && streamingResponse && (
                    <div className="flex justify-start">
                      <div className="max-w-[80%] bg-gray-200 text-gray-900 rounded-lg p-4">
                        <p className="text-sm font-semibold mb-0">ModulawAI</p>
                        <div className="whitespace-pre-wrap">{streamingResponse}</div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Input Area */}
            <footer className="bg-gray-200 dark:bg-gray-800 border-t dark:border-gray-700 p-3 sm:p-4">
              <form onSubmit={handleSendMessage} className="relative">
                <div className="relative flex flex-col">
                  {/* Action buttons container */}
                  <div className="absolute left-2 top-2 flex flex-col gap-2 z-10">
                    <FileUploadButton 
                      token={token}
                      canUploadFiles={canUploadFiles}
                      onFileSelect={setSelectedFile}
                      isUploading={isUploading}
                      setIsUploading={setIsUploading}
                    />
                    <VoiceInputButton onVoiceInput={handleVoiceInput} />
                  </div>

                  <textarea
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Don't be shy, type your research questions here..."
                    className="w-full p-2 pl-16 pr-12 border rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 
                      bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:placeholder-gray-400
                      text-sm sm:text-base min-h-[80px] sm:min-h-[100px]"
                    rows="3"
                    style={{ fontSize: '16px' }}
                  />
                  
                  <button
                    type="submit"
                    disabled={isLoading || isUploading}
                    className={`absolute right-2 bottom-2 p-2 rounded-full 
                      bg-blue-600 text-white hover:bg-blue-700 
                      disabled:opacity-50 disabled:cursor-not-allowed
                      transition-colors flex items-center justify-center`}
                    title={isUploading ? 'Please wait for file upload to complete' : 'Send message'}
                  >
                    {isLoading ? (
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                    ) : (
                      <Send className="h-5 w-5" />
                    )}
                  </button>

                  <div className="absolute left-2 top-2">
                    <FilePreviewBadge 
                      file={selectedFile} 
                      onRemove={() => setSelectedFile(null)} 
                    />
                  </div>
                </div>
              </form>
              <p className="text-xs sm:text-sm text-gray-400 mt-2 text-center italic px-4">
This is a great research tool, but verify legal advice with a qualified professional.
              </p>
            </footer>
          </div>
        </>
      )}

      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-md w-full p-6 shadow-xl">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Delete Conversation
            </h3>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              Are you sure you want to delete this conversation? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => {
                  setIsDeleteModalOpen(false);
                  setConversationToDelete(null);
                }}
                className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
        onSubmit={handleDetailedFeedback}
        initialFeedback={selectedMessageIndex !== null ? messageFeedback[selectedMessageIndex] : null}
      />
    </div>
  );
};

export default Chat;