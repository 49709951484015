import React, { useState, useEffect } from 'react';
import CaseList from './casemanagermodules/CaseList';
import CaseForm from './casemanagermodules/CaseForm';
import CaseDetails from './casemanagermodules/CaseDetails';
import { FaPlus, FaSearch, FaBriefcase, FaFileAlt, FaTasks, FaClock, FaUsers, FaChartBar, FaTachometerAlt, FaChevronLeft, FaChevronRight, FaUserCircle, FaCalendar, FaPuzzlePiece } from 'react-icons/fa';
import { caseService } from '../services/caseService';
import { toast } from 'react-toastify';
import { clientService } from '../services/clientService';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../providers/ThemeProvider';

const CaseManagerComponent = () => {
  const { theme: darkMode } = useTheme();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showNewCaseForm, setShowNewCaseForm] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [clients, setClients] = useState([]);
  const [clientSearchQuery, setClientSearchQuery] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [showClientDropdown, setShowClientDropdown] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchCases = async () => {
    try {
      setLoading(true);
      setError(null);
      const fetchedCases = await caseService.getAllCases();
      setCases(fetchedCases);
    } catch (err) {
      setError(err.message);
      toast.error('Failed to load cases');
      setCases([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCases();
  }, []);

  useEffect(() => {
    if (clientSearchQuery.length >= 2) {
      const searchClients = async () => {
        try {
          const results = await clientService.searchClients(clientSearchQuery);
          setClients(results);
          setShowClientDropdown(true);
        } catch (error) {
          console.error('Error searching clients:', error);
          toast.error('Failed to search clients');
        }
      };
      searchClients();
    } else {
      setClients([]);
      setShowClientDropdown(false);
    }
  }, [clientSearchQuery]);

  const handleCreateCase = async (caseData) => {
    try {
      const newCase = await caseService.createCase(caseData);
      setCases([...cases, newCase]);
      setShowNewCaseForm(false);
      toast.success('Case created successfully');
    } catch (error) {
      console.error('Error creating case:', error);
      toast.error('Failed to create case');
    }
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (query.length >= 3) {
      try {
        setLoading(true);
        const results = await caseService.searchCases(query);
        setCases(results);
      } catch (error) {
        console.error('Error searching cases:', error);
        toast.error('Failed to search cases');
      } finally {
        setLoading(false);
      }
    } else if (query.length === 0) {
      fetchCases();
    }
  };

  const filteredCases = cases.filter(case_ =>
    case_.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    case_.client.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleNavigation = (route) => {
    if (route === 'profile') {
      navigate('/profile');
    } else {
      console.log(`Navigating to ${route}`);
    }
  };

  const sidebarItems = [
    { icon: FaTachometerAlt, label: 'Dashboard', route: 'dashboard' },
    { icon: FaBriefcase, label: 'Cases', route: 'cases' },
    { icon: FaFileAlt, label: 'Documents', route: 'documents' },
    { icon: FaTasks, label: 'Tasks', route: 'tasks' },
    { icon: FaCalendar, label: 'Calendar', route: 'calendar' },
    { icon: FaClock, label: 'Deadlines', route: 'deadlines' },
    { icon: FaUsers, label: 'Clients', route: 'clients' },
    { icon: FaChartBar, label: 'Analytics', route: 'analytics' },
    { icon: FaPuzzlePiece, label: 'Integrations', route: 'integrations' },
    { icon: FaUserCircle, label: 'Account', route: 'profile', isProfileLink: true },
  ];

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-[calc(100vh-4rem)]">
        <div className="text-center text-red-600">
          <p>Error loading cases: {error}</p>
          <button 
            onClick={() => {
              setError(null);
              fetchCases();
            }}
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[calc(100vh-4rem)]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className={`flex h-screen ${darkMode ? 'bg-gray-950' : 'bg-gray-100'}`}>
      {/* Sidebar with Workspace */}
      <div 
        className={`fixed md:relative z-30 h-screen transition-all duration-300 ease-in-out
          ${sidebarOpen ? 'w-64' : 'w-16'} 
          ${darkMode ? 'bg-gray-900' : 'bg-gray-800'} 
          shadow-xl border-r ${darkMode ? 'border-gray-800' : 'border-gray-700'}
          ${sidebarOpen ? 'translate-x-0' : '-translate-x-48 md:translate-x-0'}`}
      >
        <div className="p-4 flex items-center justify-between border-b border-gray-700/30">
          {/* Workspace Title */}
          <div className={`flex-1 transition-opacity duration-200 
            ${sidebarOpen ? 'opacity-100' : 'opacity-0'}`}>
            <h2 className="text-white font-medium truncate">Workspace</h2>
            <p className="text-sm text-gray-400 truncate">Your Legal Hub</p>
          </div>
          {/* Toggle Button */}
          <button 
            onClick={() => setSidebarOpen(!sidebarOpen)} 
            className={`p-2 rounded-lg transition-colors
              hover:bg-gray-800
              text-blue-500 hover:text-blue-400`}
          >
            {sidebarOpen ? <FaChevronLeft /> : <FaChevronRight />}
          </button>
        </div>
        <nav className="mt-8">
          {sidebarItems.map((item) => (
            <button
              key={item.route}
              onClick={() => handleNavigation(item.route)}
              className={`w-full flex items-center p-4 transition-colors
                hover:bg-gray-800 group`}
            >
              <item.icon className="text-xl text-blue-500 group-hover:text-blue-400" />
              {sidebarOpen && (
                <span className="ml-4 font-medium text-white group-hover:text-gray-200">
                  {item.label}
                </span>
              )}
            </button>
          ))}
        </nav>
      </div>

      {/* Main Content - Centered */}
      <div className="flex-1 overflow-hidden relative">
        <div className={`p-4 md:p-6 h-full ${darkMode ? 'bg-gray-950' : 'bg-gray-100'}`}>
          <div className="max-w-7xl mx-auto h-full">
            {/* Case Manager Container */}
            <div className={`ml-0 md:ml-8 rounded-xl shadow-2xl h-full overflow-hidden
              ${darkMode ? 'bg-gray-800/50 backdrop-blur-sm' : 'bg-white/80 backdrop-blur-sm'}
              border ${darkMode ? 'border-gray-700/50' : 'border-gray-200/50'}`}>
              {/* Header Section */}
              <div className={`border-b ${darkMode ? 'border-gray-700/50' : 'border-gray-200/50'} 
                p-4 backdrop-blur-sm ${darkMode ? 'bg-gray-800/50' : 'bg-white/50'}`}>
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                  <h1 className={`text-xl md:text-2xl font-bold 
                    ${darkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                    Case Manager
                  </h1>
                  <div className="flex flex-col sm:flex-row w-full md:w-auto gap-4">
                    <div className="relative flex-grow sm:flex-grow-0">
                      <input
                        type="text"
                        placeholder="Search cases..."
                        className={`w-full pl-10 pr-4 py-2 rounded-lg focus:ring-2 focus:ring-blue-500 
                          ${darkMode ? 'bg-gray-700/50 border-gray-600' : 'bg-white/50 border-gray-300'}
                          backdrop-blur-sm transition-colors`}
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                      <FaSearch className="absolute left-3 top-3 text-gray-400" />
                    </div>
                    <button
                      onClick={() => setShowNewCaseForm(true)}
                      className={`flex items-center justify-center gap-2 px-4 py-2 rounded-lg
                        transition-all transform hover:scale-105
                        ${darkMode ? 
                          'bg-blue-600 hover:bg-blue-500 text-white' : 
                          'bg-blue-600 hover:bg-blue-700 text-white'}`}
                    >
                      <FaPlus /> New Case
                    </button>
                  </div>
                </div>
              </div>

              {/* Case Form Section - Keep existing logic but update styling */}
              {showNewCaseForm && (
                <div className={`border-b ${darkMode ? 'border-gray-700/50' : 'border-gray-200/50'}
                  ${darkMode ? 'bg-gray-800/30' : 'bg-white/30'} backdrop-blur-sm`}>
                  <div className="p-4">
                    <div className="relative mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Client
                      </label>
                      <input
                        type="text"
                        value={clientSearchQuery}
                        onChange={(e) => setClientSearchQuery(e.target.value)}
                        placeholder="Search for a client..."
                        className="w-full pl-4 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                      
                      {showClientDropdown && clients.length > 0 && (
                        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg">
                          {clients.map((client) => (
                            <div
                              key={client.id}
                              onClick={() => {
                                setSelectedClient(client);
                                setClientSearchQuery(client.name);
                                setShowClientDropdown(false);
                              }}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            >
                              {client.name}
                            </div>
                          ))}
                          <div
                            onClick={() => {
                              // Handle creating new client
                              // You can show a modal or navigate to client creation
                            }}
                            className="px-4 py-2 text-blue-600 hover:bg-gray-100 cursor-pointer border-t"
                          >
                            + Create new client "{clientSearchQuery}"
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <CaseForm
                    onSubmit={handleCreateCase}
                    onCancel={() => {
                      setShowNewCaseForm(false);
                      setClientSearchQuery('');
                      setSelectedClient(null);
                    }}
                    selectedClient={selectedClient}
                  />
                </div>
              )}

              {/* Main Grid Layout */}
              <div className="grid grid-cols-1 lg:grid-cols-12 divide-y lg:divide-y-0 lg:divide-x 
                divide-gray-200/20">
                {/* Case List */}
                <div className="lg:col-span-3 overflow-y-auto max-h-[calc(100vh-16rem)] lg:max-h-[calc(100vh-12rem)]
                  bg-gray-800/30 bg-white/30 backdrop-blur-sm">
                  <CaseList
                    cases={filteredCases}
                    onSelectCase={setSelectedCase}
                    selectedCaseId={selectedCase?.id}
                  />
                </div>
                
                {/* Case Details */}
                <div className="lg:col-span-9 overflow-y-auto max-h-[calc(100vh-16rem)] lg:max-h-[calc(100vh-12rem)]
                  bg-gray-800/30 bg-white/30 backdrop-blur-sm">
                  {selectedCase ? (
                    <CaseDetails
                      case={selectedCase}
                      onUpdate={(updatedCase) => {
                        setCases(cases.map(c => 
                          c.id === updatedCase.id ? updatedCase : c
                        ));
                        setSelectedCase(updatedCase);
                      }}
                    />
                  ) : (
                    <div className="flex items-center justify-center h-full p-6">
                      <div className="text-center">
                        <p className={`text-lg ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                          Select a case to view details
                        </p>
                        <p className={`text-sm mt-2 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                          Or create a new case to get started
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Enhanced overlay for mobile */}
      {sidebarOpen && (
        <div 
          className="fixed inset-0 bg-black/60 backdrop-blur-sm z-20 md:hidden"
          onClick={() => setSidebarOpen(false)}
        />
      )}
    </div>
  );
};

export default CaseManagerComponent;
