import React, { useRef } from 'react';
import { Paperclip } from 'lucide-react';
import toast from 'react-hot-toast';
import api from '../../services/api';

const FileUploadButton = ({ 
  token, 
  canUploadFiles, 
  onFileSelect, 
  isUploading, 
  setIsUploading 
}) => {
  const fileInputRef = useRef(null);

  const handleAttachmentClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileUpload = async (event) => {
    // Check plan status first
    if (!canUploadFiles()) {
      toast.error('File upload requires Professional plan or higher');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      return;
    }

    const file = event.target.files?.[0];
    if (!file) return;

    // Updated file validation checks
    const allowedTypes = [
      'application/pdf',
      'application/msword', // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // .docx
    ];
    
    if (!allowedTypes.includes(file.type)) {
      toast.error('Only PDF, DOC, and DOCX files are allowed');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      return;
    }

    const maxSize = 10 * 1024 * 1024;
    if (file.size > maxSize) {
      toast.error('File size exceeds 10MB limit');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', file);

    let processedContent = '';
    let currentToast = null;

    try {
      const response = await fetch(`${api.defaults.baseURL}/api/documents/process`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.trim() && line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(5));

              if (data.status === 'started') {
                currentToast = toast.loading('Starting document analysis...', { id: 'upload-progress' });
              } 
              else if (data.status === 'processing') {
                toast.loading(`Processing document... ${data.progress}%`, { id: currentToast });
              }
              else if (data.status === 'page_complete') {
                processedContent += data.content + '\n';
                toast.loading(`Processed page ${data.pageNumber} (${data.progress}%)`, { id: currentToast });
              }
              else if (data.status === 'complete') {
                onFileSelect({
                  name: file.name,
                  type: file.type,
                  preview: null,
                  processedContent: data.content
                });

                toast.success(
                  `Successfully processed: ${file.name} (${data.pageCount} pages)`, 
                  { id: currentToast }
                );
              }
              else if (data.status === 'error') {
                throw new Error(data.message);
              }
            } catch (error) {
              if (!line.includes('[DONE]')) {
                console.error('Error parsing chunk:', error);
              }
            }
          }
        }
      }

    } catch (error) {
      console.error('Upload error:', error);
      toast.error(error.message || 'Error processing PDF file', { id: 'upload-progress' });
      onFileSelect(null);
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div className="group">
      <button
        type="button"
        className={`p-2 rounded-full 
          ${!canUploadFiles() ? 'bg-gray-400 cursor-not-allowed' : isUploading ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'} 
          transition-colors flex items-center justify-center`}
        onClick={canUploadFiles() ? handleAttachmentClick : () => toast.error('File upload requires Professional plan or higher')}
        disabled={isUploading || !canUploadFiles()}
      >
        {isUploading ? (
          <div className="animate-spin">
            <svg className="w-5 h-5 text-white" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
          </div>
        ) : (
          <Paperclip className="h-5 w-5 text-white" />
        )}
      </button>

      {/* Tooltip - only show for non-Professional users */}
      {!canUploadFiles() && (
        <div className="absolute bottom-full left-0 mb-2 px-2 py-1 text-xs text-white 
          bg-gray-800 rounded shadow-lg 
          transition-opacity duration-200
          opacity-0 group-hover:opacity-100
          whitespace-nowrap"
        >
          Professional plan required for file upload
        </div>
      )}

      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        className="hidden"
        accept=".pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
    </div>
  );
};

export default FileUploadButton; 