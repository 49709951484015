import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../services/api';

export const fetchConversations = createAsyncThunk(
  'conversations/fetchConversations',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/conversations?page=1');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addConversation = createAsyncThunk(
  'conversations/addConversation',
  async (conversation, { dispatch }) => {
    return conversation;
  }
);

export const updateConversation = createAsyncThunk(
  'conversations/updateConversation',
  async ({ conversationId, messages }, { getState }) => {
    try {
      const response = await api.put(
        `/api/conversations/${conversationId}`,
        { messages }
      );
      
      if (!response.data) {
        throw new Error('No data received from conversation update');
      }
      
      return response.data;
    } catch (error) {
      console.error('Error updating conversation:', error.response?.data || error.message);
      throw error;
    }
  }
);

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: {
    conversations: [],
    status: 'idle',
    error: null,
    hasMore: true,
    currentPage: 1
  },
  reducers: {
    resetConversationsStatus: (state) => {
      state.status = 'idle';
    },
    addConversation: (state, action) => {
      state.conversations.unshift(action.payload);
    },
    removeConversation: (state, action) => {
      state.conversations = state.conversations.filter(
        conv => conv._id !== action.payload
      );
    },
    setConversations: (state, action) => {
      state.conversations = action.payload;
    },
    addMore: (state, action) => {
      state.conversations.push(...action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchConversations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.conversations = action.payload.conversations;
        state.hasMore = action.payload.hasMore;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchConversations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(addConversation.fulfilled, (state, action) => {
        state.conversations.unshift(action.payload);
      })
      .addCase(updateConversation.fulfilled, (state, action) => {
        const index = state.conversations.findIndex(conv => conv._id === action.payload._id);
        if (index !== -1) {
          state.conversations[index] = {
            ...state.conversations[index],
            ...action.payload,
            messages: action.payload.messages
          };
        }
      })
      .addCase(updateConversation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetConversationsStatus, addMore } = conversationsSlice.actions;

export default conversationsSlice.reducer;
