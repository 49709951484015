import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import { motion } from 'framer-motion';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import api from '../services/api';
import { toast } from 'react-hot-toast';

const SignupForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    userType: '',
    password: '',
    confirmPassword: '',
    acceptedTerms: false,
    newsletterConsent: false,
    referralSource: '',
    phoneNumber: '',
  });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [step, setStep] = useState(1);
  const [verificationSent, setVerificationSent] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState('');
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [invitePending, setInvitePending] = useState(false);

  useEffect(() => {
    // Get URL parameters
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const invitePending = params.get('invitePending');
    
    // Pre-fill email if provided
    if (email) {
      setFormData(prev => ({
        ...prev,
        email
      }));
    }
    
    // Set invitation pending status
    if (invitePending === 'true') {
      setInvitePending(true);
    }
  }, []);

  const checkPasswordStrength = (password) => {
    const result = zxcvbn(password);
    setPasswordStrength(result.score); // 0-4 score
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ 
      ...formData, 
      [name]: type === 'checkbox' ? checked : value 
    });
    
    if (name === 'password') {
      checkPasswordStrength(value);
    }
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 0: 
        return 'bg-red-500';
      case 1: 
        return 'bg-orange-500';
      case 2: 
        return 'bg-yellow-500';
      case 3: 
        return 'bg-blue-500';
      case 4: 
        return 'bg-green-500';
      default:
        return 'bg-gray-200';
    }
  };

  const validateEmail = async (email) => {
    try {
      setIsCheckingEmail(true);
      const response = await api.post('/api/auth/check-email', { email });
      
      // Add logging to debug the response
      console.log('Email check response:', response.data);
      
      // Check if the response indicates email exists
      if (response.data && response.data.exists === true) {
        setError('This email is already registered. Please use a different email or login.');
        return false;
      }
      
      // Clear any existing error if email is valid
      setError('');
      return true;
    } catch (error) {
      // Log the full error for debugging
      console.error('Email validation error:', error);
      
      // Check if it's a specific API error
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Error validating email. Please try again.');
      }
      return false;
    } finally {
      setIsCheckingEmail(false);
    }
  };

  const validateStep = async (step) => {
    switch (step) {
      case 1:
        if (!formData.email) {
          setError('Email is required');
          return false;
        } 
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
          setError('Email is invalid');
          return false;
        }
        
        const isEmailValid = await validateEmail(formData.email);
        if (!isEmailValid) {
          return false;
        }
        return true;
      
      case 2:
        if (!formData.username || !formData.firstName || !formData.lastName || 
            !formData.userType || !formData.referralSource || !formData.phoneNumber) {
          setError('All fields are required');
          return false;
        }
        if (formData.phoneNumber && !/^\+?[\d\s-]{10,}$/.test(formData.phoneNumber)) {
          setError('Please enter a valid phone number');
          return false;
        }
        return true;

      case 3:
        if (!formData.password) {
          setError('Password is required');
          return false;
        } 
        if (formData.password.length < 8) {
          setError('Password must be at least 8 characters');
          return false;
        }
        if (!formData.confirmPassword) {
          setError('Please confirm your password');
          return false;
        } 
        if (formData.password !== formData.confirmPassword) {
          setError('Passwords do not match');
          return false;
        }
        if (!formData.acceptedTerms) {
          setError('You must accept the Terms of Service to continue');
          return false;
        }
        return true;

      default:
        setError('Invalid step number');
        return false;
    }
  };

  const handleNext = async () => {
    const isValid = await validateStep(step);
    if (isValid) {
      setError('');
      setStep(step + 1);
    }
  };

  const handleSignupSuccess = () => {
    if (invitePending) {
      toast.success('Account created! Please check your email to verify your account and accept the invitation.');
    } else {
      toast.success('Account created! Please check your email to verify your account.');
    }
    setVerificationSent(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (step !== 3) {
      await handleNext();
      return;
    }
    
    setError('');

    if (passwordStrength < 2) {
      setError('Please choose a stronger password');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      // Add logging before registration attempt
      console.log('Attempting registration with:', { ...formData, password: '[REDACTED]' });
      
      const response = await api.post('/api/auth/register', formData);
      console.log('Registration successful:', response.data);
      setRegisteredEmail(formData.email);
      handleSignupSuccess();
    } catch (error) {
      console.error('Registration error:', error);
      
      // More specific error handling
      if (error.response) {
        const errorMessage = error.response.data.message || 'An error occurred during registration';
        if (error.response.status === 409) {
          setError('This email or username is already registered. Please try another.');
        } else if (error.response.status === 400) {
          setError(errorMessage);
        } else {
          setError('An error occurred while signing up. Please try again.');
        }
      } else if (error.request) {
        setError('Unable to connect to the server. Please check your internet connection.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    }
  };

  const renderProgressSteps = () => (
    <div className="w-full px-4 mb-8">
      <div className="relative flex items-center justify-between">
        {[
          { step: 1, label: "Email" },
          { step: 2, label: "Profile" },
          { step: 3, label: "Security" }
        ].map(({ step: stepNumber, label }) => (
          <div key={stepNumber} className="relative flex flex-col items-center">
            <div
              className={`w-10 h-10 rounded-full flex items-center justify-center ${
                stepNumber <= step
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-200 text-gray-600'
              }`}
            >
              {stepNumber < step ? (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              ) : (
                stepNumber
              )}
            </div>
            <span className={`mt-2 text-xs ${
              stepNumber <= step ? 'text-blue-600' : 'text-gray-500'
            }`}>
              {label}
            </span>
            {stepNumber < 3 && (
              <div className={`absolute w-full h-[2px] top-5 -right-1/2 ${
                stepNumber < step ? 'bg-blue-600' : 'bg-gray-200'
              }`} />
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="space-y-6"
          >
            <div className="text-center mb-8">
              <h3 className="text-2xl font-bold text-gray-900">Welcome to ModulawAI</h3>
              <p className="text-gray-600 mt-2">First, let's verify your email</p>
            </div>
            
            <div className="space-y-4">
              <label className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <div className="relative">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter your email"
                  disabled={isCheckingEmail}
                />
                {isCheckingEmail && (
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                    <svg className="animate-spin h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        );

      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="space-y-6"
          >
            <div className="text-center mb-8">
              <h3 className="text-2xl font-bold text-gray-900">Complete Your Profile</h3>
              <p className="text-gray-600 mt-2">Tell us more about yourself</p>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter first name"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter last name"
                />
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Username <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Choose a username"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  User Type <span className="text-red-500">*</span>
                </label>
                <select
                  name="userType"
                  value={formData.userType}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select your role</option>
                  <option value="lawyer">Lawyer</option>
                  <option value="client">Client</option>
                  <option value="judge">Judge</option>
                  <option value="law_student">Law Student</option>
                  <option value="legal_researcher">Legal Researcher</option>
                  <option value="paralegal">Paralegal</option>
                  <option value="legal_secretary">Legal Secretary</option>
                  <option value="law_professor">Law Professor</option>
                  <option value="court_staff">Court Staff</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  How did you hear about us? <span className="text-red-500">*</span>
                </label>
                <select
                  name="referralSource"
                  value={formData.referralSource}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select an option</option>
                  <option value="search_engine">Search Engine (Google, Bing, etc.)</option>
                  <option value="social_media">Social Media</option>
                  <option value="friend_referral">Friend or Colleague</option>
                  <option value="legal_conference">Legal Conference or Event</option>
                  <option value="bar_association">Bar Association</option>
                  <option value="legal_publication">Legal Publication or Blog</option>
                  <option value="email_marketing">Email Newsletter</option>
                  <option value="online_ad">Online Advertisement</option>
                  <option value="law_school">Law School</option>
                  <option value="law_firm">Law Firm</option>
                  <option value="court_referral">Court System</option>
                  <option value="other">Other</option>
                </select>
              </div>

              {formData.referralSource === 'other' && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Please specify <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="referralSourceOther"
                    value={formData.referralSourceOther || ''}
                    onChange={handleChange}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Tell us how you found us"
                  />
                </div>
              )}

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Phone Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter your phone number"
                />
                <p className="mt-1 text-xs text-gray-500">
                  Format: +234 XXX XXX XXXX or 0XXX XXX XXXX
                </p>
              </div>
            </div>
          </motion.div>
        );

      case 3:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="space-y-6"
          >
            <div className="text-center mb-8">
              <h3 className="text-2xl font-bold text-gray-900">Secure Your Account</h3>
              <p className="text-gray-600 mt-2">Create a strong password to protect your account</p>
            </div>

            <div className="mb-4">
              <label className="block text-gray-600 text-sm font-medium mb-2">
                Password *
              </label>
              <div className="relative">
                <input
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Create a strong password"
                  minLength={8}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-600 text-sm font-medium mb-2">
                Confirm Password *
              </label>
              <input
                name="confirmPassword"
                type={showPassword ? "text" : "password"}
                value={formData.confirmPassword}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Confirm your password"
                minLength={8}
              />
            </div>

            {formData.password && (
              <div className="mb-6">
                <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
                  <div 
                    className={`h-full transition-all duration-300 ${getPasswordStrengthColor()}`}
                    style={{ width: `${(passwordStrength + 1) * 20}%` }}
                  />
                </div>
                <p className="text-xs text-gray-600 mt-1">
                  Password strength: {' '}
                  {passwordStrength === 0 && "Very weak"}
                  {passwordStrength === 1 && "Weak"}
                  {passwordStrength === 2 && "Fair"}
                  {passwordStrength === 3 && "Strong"}
                  {passwordStrength === 4 && "Very strong"}
                </p>
              </div>
            )}

            <div className="space-y-4 mt-6">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="acceptedTerms"
                    name="acceptedTerms"
                    type="checkbox"
                    checked={formData.acceptedTerms}
                    onChange={handleChange}
                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    required
                  />
                </div>
                <div className="ml-3">
                  <label htmlFor="acceptedTerms" className="text-sm text-gray-700">
                    I accept the{' '}
                    <Link to="/terms" className="text-blue-600 hover:text-blue-800 font-medium">
                      Terms of Service
                    </Link>
                    {' '}and{' '}
                    <Link to="/privacy" className="text-blue-600 hover:text-blue-800 font-medium">
                      Privacy Policy
                    </Link>
                    <span className="text-red-500">*</span>
                  </label>
                </div>
              </div>

              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="newsletterConsent"
                    name="newsletterConsent"
                    type="checkbox"
                    checked={formData.newsletterConsent}
                    onChange={handleChange}
                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                </div>
                <div className="ml-3">
                  <label htmlFor="newsletterConsent" className="text-sm text-gray-700">
                    I want to receive updates about products, features, and legal tech news
                  </label>
                  <p className="mt-1 text-xs text-gray-500">
                    We'll send you emails about our products and legal technology. You can unsubscribe at any time.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        );

      default:
        return (
          <div className="text-center">
            <p className="text-red-600">Invalid step encountered. Please try again.</p>
            <button
              onClick={() => setStep(1)}
              className="mt-4 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-medium transition-colors"
            >
              Return to Start
            </button>
          </div>
        );
    }
  };

  const VerificationSentScreen = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="text-center"
    >
      <div className="mb-6">
        <div className="w-16 h-16 bg-green-100 rounded-full mx-auto mb-4 flex items-center justify-center">
          <svg 
            className="w-8 h-8 text-green-500" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M5 13l4 4L19 7" 
            />
          </svg>
        </div>
        <h3 className="text-2xl font-bold text-gray-900 mb-2">Verify your email</h3>
        <p className="text-gray-600">
          We've sent a verification email to:
          <br />
          <span className="font-medium text-gray-900">{registeredEmail}</span>
        </p>
      </div>

      <div className="space-y-4">
        <p className="text-sm text-gray-600">
          Please check your email and click the verification link to activate your account.
        </p>
        <div className="pt-4 border-t border-gray-200">
          <p className="text-sm text-gray-600 mb-2">Didn't receive the email?</p>
          <button
            onClick={async () => {
              try {
                await api.post('/api/auth/resend-verification', {
                  email: registeredEmail
                });
                setError('Verification email resent successfully!');
              } catch (err) {
                setError('Failed to resend verification email. Please try again.');
              }
            }}
            className="text-blue-600 hover:text-blue-800 font-medium text-sm"
          >
            Click to resend
          </button>
        </div>
        <div className="pt-4">
          <Link
            to="/login"
            className="inline-flex items-center text-blue-600 hover:text-blue-800 font-medium text-sm"
          >
            <svg 
              className="w-4 h-4 mr-2" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M10 19l-7-7m0 0l7-7m-7 7h18" 
              />
            </svg>
            Back to Login
          </Link>
        </div>
      </div>
    </motion.div>
  );

  return (
    <div className="relative min-h-screen bg-blue-900 bg-gradient-to-b from-blue-900 via-blue-800 to-blue-600 font-[Poppins]">
      <div className="relative w-full min-h-screen overflow-x-hidden pb-32 sm:pb-0 flex pt-20 sm:pt-0 sm:items-center">
        <div className="w-full">
          <div className="relative sm:flex sm:flex-row justify-center bg-transparent rounded-3xl shadow-xl">
            <div className="flex-col flex self-center lg:px-14 sm:max-w-4xl xl:max-w-md z-10">
              <div className="self-start hidden lg:flex flex-col text-gray-300">
                <h1 className="my-3 font-bold text-5xl font-[Inter] tracking-tight">
                  Create Account
                </h1>
                <p className="pr-3 text-lg font-light opacity-85">
                  Join ModulawAI to enjoy lightning-fast research and case management
                </p>
              </div>
            </div>

            <div className="flex justify-center self-center z-20 px-4 sm:px-0 mb-20 sm:mb-0">
              {verificationSent ? (
                <div className="p-8 sm:p-12 bg-white mx-auto rounded-3xl w-full sm:w-96 shadow-xl">
                  <VerificationSentScreen />
                </div>
              ) : (
                <div className="p-8 sm:p-12 bg-white mx-auto rounded-3xl w-full sm:w-96 shadow-xl">
                  {renderProgressSteps()}
                  
                  {error && (
                    <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-600 rounded-lg">
                      {error}
                    </div>
                  )}

                  <form onSubmit={handleSubmit}>
                    {renderStepContent()}

                    <div className="mt-8 flex justify-between">
                      {step > 1 && (
                        <button
                          type="button"
                          onClick={() => setStep(step - 1)}
                          className="px-6 py-3 text-blue-600 hover:text-blue-700 font-medium"
                        >
                          Back
                        </button>
                      )}
                      <button
                        type="submit"
                        className="ml-auto px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-medium transition-colors"
                      >
                        {step === 3 ? 'Create Account' : 'Continue'}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 left-0 right-0 w-full overflow-hidden">
        <svg 
          className="w-full h-auto"
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
          style={{ minHeight: '120px' }}
        >
          <path 
            fill="#fff" 
            fillOpacity="1" 
            d="M0,0L40,42.7C80,85,160,171,240,197.3C320,224,400,192,480,154.7C560,117,640,75,720,74.7C800,75,880,117,960,154.7C1040,192,1120,224,1200,213.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
          />
        </svg>
      </div>
    </div>
  );
};

export default SignupForm;
