export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  TRIAL: 'trial'
};

// Type guard for subscription status
export const isValidSubscriptionStatus = (status) => {
  return Object.values(SUBSCRIPTION_STATUS).includes(status);
};

// Helper to get display text for each status
export const getSubscriptionStatusText = (status) => {
  switch (status) {
    case SUBSCRIPTION_STATUS.ACTIVE:
      return 'Active';
    case SUBSCRIPTION_STATUS.INACTIVE:
      return 'Inactive';
    case SUBSCRIPTION_STATUS.PENDING:
      return 'Pending';
    case SUBSCRIPTION_STATUS.FAILED:
      return 'Failed';
    case SUBSCRIPTION_STATUS.CANCELLED:
      return 'Cancelled';
    case SUBSCRIPTION_STATUS.EXPIRED:
      return 'Expired';
    case SUBSCRIPTION_STATUS.TRIAL:
      return 'Trial';
    default:
      return 'Unknown';
  }
};

// Helper to get status color/style class
export const getSubscriptionStatusClass = (status) => {
  switch (status) {
    case SUBSCRIPTION_STATUS.ACTIVE:
      return 'text-green-600 bg-green-100';
    case SUBSCRIPTION_STATUS.INACTIVE:
      return 'text-gray-600 bg-gray-100';
    case SUBSCRIPTION_STATUS.PENDING:
      return 'text-yellow-600 bg-yellow-100';
    case SUBSCRIPTION_STATUS.FAILED:
      return 'text-red-600 bg-red-100';
    case SUBSCRIPTION_STATUS.CANCELLED:
      return 'text-red-600 bg-red-100';
    case SUBSCRIPTION_STATUS.EXPIRED:
      return 'text-red-600 bg-red-100';
    case SUBSCRIPTION_STATUS.TRIAL:
      return 'text-blue-600 bg-blue-100';
    default:
      return 'text-gray-600 bg-gray-100';
  }
}; 