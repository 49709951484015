import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import toast from 'react-hot-toast';

const AcceptInvitation = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');

  useEffect(() => {
    const acceptInvitation = async () => {
      try {
        setIsLoading(true);
        console.log('Accepting invitation with token:', token);

        const response = await api.post(`/api/workspaces/join/${token}`);
        
        if (response.data.success) {
          setSuccess(true);
          setWorkspaceName(response.data.data?.workspace?.name || 'the workspace');
          toast.success('Invitation accepted successfully');
          
          // Delay navigation to show success message
          setTimeout(() => {
            navigate('/dashboard');
          }, 3000); // 3 seconds delay
        } else {
          throw new Error(response.data.message || 'Failed to accept invitation');
        }
      } catch (error) {
        console.error('Accept invitation error:', error);
        setError(error.response?.data?.message || 'Failed to accept invitation');
        toast.error(error.response?.data?.message || 'Failed to accept invitation');
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      acceptInvitation();
    }
  }, [token, navigate]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600 dark:text-gray-300">Processing your invitation...</p>
        </div>
      </div>
    );
  }

  if (success) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg max-w-md w-full">
          <div className="text-green-500 text-5xl mb-4">✓</div>
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
            Welcome to {workspaceName}!
          </h2>
          <p className="text-gray-600 dark:text-gray-300 mb-6">
            You have successfully joined the workspace. You will be redirected to the dashboard in a few seconds.
          </p>
          <button
            onClick={() => navigate('/dashboard')}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Go to Dashboard Now
          </button>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg max-w-md w-full">
          <div className="text-red-500 text-5xl mb-4">⚠️</div>
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
            Failed to Accept Invitation
          </h2>
          <p className="text-gray-600 dark:text-gray-300 mb-6">{error}</p>
          <button
            onClick={() => navigate('/dashboard')}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    );
  }

  return null;
};

export default AcceptInvitation; 