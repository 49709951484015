import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {PlusCircle, Trash, X, ChevronRight, FileText, CheckSquare, Square, Briefcase, LogOut, Crown, ChevronLeft, Share2} from 'lucide-react';
import Logo from '../Logo';
import api from '../../services/api';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';

const isProduction = process.env.NODE_ENV === 'production';

const ChatSidebar = ({
  isDesktopSidebarCollapsed,
  setIsDesktopSidebarCollapsed,
  isSidebarOpen,
  setIsSidebarOpen,
  isMultiDeleteMode,
  setIsMultiDeleteMode,
  selectedConversations,
  setSelectedConversations,
  handleMultiDelete,
  userConversations,
  currentConversationId,
  loadConversation,
  expandedGroups,
  setExpandedGroups,
  groupConversations,
  handleLogout,
  user,
  navigate
}) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const dispatch = useDispatch();

  const toggleMultiDeleteMode = () => {
    setIsMultiDeleteMode(!isMultiDeleteMode);
    setSelectedConversations(new Set());
  };

  const toggleConversationSelection = (convId, e) => {
    e.stopPropagation();
    setSelectedConversations(prev => {
      const newSet = new Set(prev);
      if (newSet.has(convId)) {
        newSet.delete(convId);
      } else {
        newSet.add(convId);
      }
      return newSet;
    });
  };

  const toggleGroupExpansion = (groupTitle) => {
    setExpandedGroups(prev => {
      const newSet = new Set(prev);
      if (newSet.has(groupTitle)) {
        newSet.delete(groupTitle);
      } else {
        newSet.add(groupTitle);
      }
      return newSet;
    });
  };

  const toggleGroupSelection = (conversations) => {
    setSelectedConversations(prev => {
      const newSet = new Set(prev);
      const conversationIds = conversations.map(conv => conv._id);
      
      const allSelected = conversationIds.every(id => newSet.has(id));
      
      if (allSelected) {
        conversationIds.forEach(id => newSet.delete(id));
      } else {
        conversationIds.forEach(id => newSet.add(id));
      }
      
      return newSet;
    });
  };

  const LogoutConfirmationModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-gray-800 rounded-lg p-6 max-w-sm w-full mx-4 border border-gray-700 shadow-xl">
        <h3 className="text-lg font-medium text-white mb-4">Confirm Logout</h3>
        <p className="text-gray-300 mb-6">Are you sure you want to logout?</p>
        <div className="flex justify-end gap-3">
          <button
            onClick={() => setShowLogoutModal(false)}
            className="px-4 py-2 rounded-md bg-gray-700 text-white hover:bg-gray-600 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setShowLogoutModal(false);
              handleLogout();
            }}
            className="px-4 py-2 rounded-md bg-red-600 text-white hover:bg-red-700 transition-colors"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );

  const DeleteConfirmationModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-gray-800 rounded-lg p-6 max-w-sm w-full mx-4 border border-gray-700 shadow-xl">
        <h3 className="text-lg font-medium text-white mb-4">Confirm Deletion</h3>
        <p className="text-gray-300 mb-2">Are you sure you want to delete {selectedConversations.size} conversation{selectedConversations.size !== 1 ? 's' : ''}?</p>
        <p className="text-red-400 text-sm mb-6">This action cannot be undone.</p>
        <div className="flex justify-end gap-3">
          <button
            onClick={() => setShowDeleteModal(false)}
            className="px-4 py-2 rounded-md bg-gray-700 text-white hover:bg-gray-600 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setShowDeleteModal(false);
              handleMultiDelete();
            }}
            className="px-4 py-2 rounded-md bg-red-600 text-white hover:bg-red-700 transition-colors"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );

  const handleDeleteClick = () => {
    if (selectedConversations.size > 0) {
      setShowDeleteModal(true);
    }
  };

  const CaseManagerLink = () => {
    const content = (
      <>
        <Briefcase className="h-4 w-4" />
        {!isDesktopSidebarCollapsed && <span className="ml-2 text-sm">Case Manager</span>}
      </>
    );

    if (isProduction) {
      return (
        <div className="flex items-center cursor-not-allowed">
          {content}
          {!isDesktopSidebarCollapsed && (
            <span className="absolute -top-2 -right-2 bg-gray-500 text-xs px-1.5 py-0.5 rounded-full text-white font-medium">
              SOON
            </span>
          )}
        </div>
      );
    }

    return (
      <Link to="/case-manager" className="flex items-center w-full">
        {content}
        {!isDesktopSidebarCollapsed && (
          <span className="absolute -top-2 -right-2 bg-blue-500 text-xs px-1.5 py-0.5 rounded-full text-white font-medium animate-pulse">
            BETA
          </span>
        )}
      </Link>
    );
  };

  const handleLoadMore = async () => {
    if (isLoadingMore || !hasMore) return;
    
    setIsLoadingMore(true);
    try {
      const nextPage = page + 1;
      const response = await api.get(`/api/conversations?page=${nextPage}`);
      
      if (response.data.conversations.length > 0) {
        // Update conversations in Redux store
        dispatch({
          type: 'conversations/addMore',
          payload: response.data.conversations
        });
        
        setPage(nextPage);
        setHasMore(response.data.hasMore);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error loading more conversations:', error);
      toast.error('Failed to load more conversations');
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleShare = async (conversationId, e) => {
    e.stopPropagation();
    
    try {
      const response = await api.post(`/api/conversations/${conversationId}/share`, {
        isPublic: true
      });
      
      if (response.data.shareId) {
        const url = `${window.location.origin}/share/${response.data.shareId}`;
        setShareUrl(url);
        setShowShareModal(true);
      }
    } catch (error) {
      console.error('Error sharing conversation:', error);
      toast.error('Failed to share conversation');
    }
  };

  const ShareModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-gray-100 w-full mx-4 p-4 rounded-xl md:w-1/2 lg:w-1/3">
        {/* Modal Header */}
        <div className="flex justify-between items center border-b border-gray-200 py-3">
          <div className="flex items-center justify-center">
            <p className="text-xl font-bold text-gray-800">Share Conversation</p>
          </div>
          <button
            onClick={() => setShowShareModal(false)}
            className="bg-gray-300 hover:bg-gray-500 cursor-pointer hover:text-gray-300 font-sans text-gray-500 w-8 h-8 flex items-center justify-center rounded-full"
          >
            x
          </button>
        </div>

        {/* Modal Body */}
        <div className="my-4">
          <p className="text-sm">Share this link via</p>

          <div className="flex justify-around my-4">
            {/* Facebook */}
            <a 
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="border hover:bg-[#1877f2] w-12 h-12 fill-[#1877f2] hover:fill-white border-blue-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-blue-500/50 cursor-pointer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
              </svg>
            </a>

            {/* X (Twitter) */}
            <a 
              href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=Check out this conversation`}
              target="_blank"
              rel="noopener noreferrer"
              className="border hover:bg-[#1d9bf0] w-12 h-12 fill-[#1d9bf0] hover:fill-white border-blue-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-sky-500/50 cursor-pointer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
              </svg>
            </a>

            {/* LinkedIn */}
            <a 
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="border hover:bg-[#0077b5] w-12 h-12 fill-[#0077b5] hover:fill-white border-blue-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-blue-500/50 cursor-pointer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
              </svg>
            </a>

            {/* WhatsApp */}
            <a 
              href={`https://wa.me/?text=${encodeURIComponent(shareUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="border hover:bg-[#25D366] w-12 h-12 fill-[#25D366] hover:fill-white border-green-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-green-500/50 cursor-pointer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fillRule="evenodd" clipRule="evenodd" d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"></path>
              </svg>
            </a>

            {/* Telegram */}
            <a 
              href={`https://t.me/share/url?url=${encodeURIComponent(shareUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="border hover:bg-[#229ED9] w-12 h-12 fill-[#229ED9] hover:fill-white border-sky-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-sky-500/50 cursor-pointer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"></path>
              </svg>
            </a>
          </div>

          <p className="text-sm">Or copy link</p>
          {/* Copy Link Box */}
          <div className="border-2 border-gray-200 flex justify-between items-center mt-4 py-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="fill-gray-500 ml-2"
            >
              <path d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z"></path>
              <path d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z"></path>
            </svg>
            <input
              className="w-full outline-none bg-transparent"
              type="text"
              readOnly
              value={shareUrl}
            />
            <button
              onClick={async () => {
                await navigator.clipboard.writeText(shareUrl);
                toast.success('Link copied to clipboard!');
              }}
              className="bg-indigo-500 text-white rounded text-sm py-2 px-5 mr-2 hover:bg-indigo-600"
            >
              Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <aside className={`fixed inset-y-0 left-0 z-50 ${
        isDesktopSidebarCollapsed ? 'w-16' : 'w-64 md:w-72 lg:w-80'
      } bg-gray-900 dark:bg-gray-800 text-white transform ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
      } transition-all duration-300 ease-in-out lg:relative flex flex-col`}>
      {/* Toggle button for desktop */}
      <button
        className="hidden lg:flex absolute -right-3 top-1/2 transform -translate-y-1/2 
        bg-gray-900 dark:bg-gray-800 rounded-full p-1 border border-gray-700 
        hover:bg-gray-800 transition-colors z-50"
        onClick={() => setIsDesktopSidebarCollapsed(!isDesktopSidebarCollapsed)}
      >
        {isDesktopSidebarCollapsed ? (
          <ChevronRight className="h-4 w-4 text-gray-400" />
        ) : (
          <ChevronLeft className="h-4 w-4 text-gray-400" />
        )}
      </button>

      {/* Header */}
      <div className="flex-shrink-0 flex items-center justify-between p-4 border-b border-gray-800">
        {!isDesktopSidebarCollapsed && (
          <Logo 
            className="h-5 w-auto sm:h-6 md:h-7 lg:h-8" 
            variant="white"
            isDark={false}
          />
        )}
        <button
          className="lg:hidden p-2 hover:bg-gray-800 rounded-md"
          onClick={() => setIsSidebarOpen(false)}
          aria-label="Close sidebar"
        >
          <X className="h-6 w-6" />
        </button>
      </div>

      {/* New Chat and Multi-Delete Section */}
      <div className="flex-shrink-0 p-4 space-y-2">
        {isMultiDeleteMode ? (
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-300">
                Select conversations to delete
              </span>
              <button
                onClick={() => {
                  setIsMultiDeleteMode(false);
                  setSelectedConversations(new Set());
                }}
                className="text-sm text-gray-400 hover:text-gray-200 flex items-center"
              >
                <X className="h-4 w-4 mr-1" />
                Exit
              </button>
            </div>
            
            {selectedConversations.size > 0 && (
              <button
                className="w-full bg-red-600 text-white px-4 py-2 rounded-md flex items-center justify-center hover:bg-red-700 transition-colors"
                onClick={handleDeleteClick}
              >
                <Trash className="h-4 w-4 mr-2" />
                Delete {selectedConversations.size} selected
              </button>
            )}
          </div>
        ) : (
          <div className="flex gap-2">
            <button
              className={`flex-1 bg-blue-600 text-white px-4 py-2 rounded-md flex items-center ${
                isDesktopSidebarCollapsed ? 'justify-center' : 'justify-center'
              } hover:bg-blue-700`}
              onClick={() => {
                loadConversation(null);
              }}
            >
              <PlusCircle className="h-4 w-4" />
              {!isDesktopSidebarCollapsed && <span className="ml-2">New Chat</span>}
            </button>
            
            {!isDesktopSidebarCollapsed && (
              <button
                className="px-4 py-2 rounded-md flex items-center justify-center bg-gray-700 hover:bg-gray-600 text-white transition-colors"
                onClick={toggleMultiDeleteMode}
                title="Delete multiple chats"
              >
                <Trash className="h-4 w-4" />
              </button>
            )}
          </div>
        )}

        {/* Select All option */}
        {isMultiDeleteMode && !isDesktopSidebarCollapsed && userConversations.length > 0 && (
          <div className="px-4 py-2 border-t border-gray-700">
            <button
              onClick={() => {
                const allConversations = userConversations.map(conv => conv._id);
                setSelectedConversations(prev => {
                  const newSet = new Set(prev);
                  const allSelected = allConversations.every(id => newSet.has(id));
                  
                  if (allSelected) {
                    newSet.clear();
                  } else {
                    allConversations.forEach(id => newSet.add(id));
                  }
                  
                  return newSet;
                });
              }}
              className="flex items-center text-sm text-gray-400 hover:text-gray-300 w-full justify-between"
            >
              <span className="flex items-center">
                {userConversations.every(conv => 
                  selectedConversations.has(conv._id)
                ) ? (
                  <>
                    <CheckSquare className="h-4 w-4 mr-2 text-blue-500" />
                    Deselect All
                  </>
                ) : (
                  <>
                    <Square className="h-4 w-4 mr-2" />
                    Select All
                  </>
                )}
              </span>
              <span className="text-xs text-gray-500">
                {selectedConversations.size} / {userConversations.length}
              </span>
            </button>
          </div>
        )}
      </div>

      {/* Conversations List */}
      <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
        <div className="px-4">
          {groupConversations(userConversations).map((group) => (
            <div key={group.title} className="mb-4">
              {!isDesktopSidebarCollapsed && (
                <div className="flex items-center justify-between px-4 py-2">
                  <div className="flex items-center">
                    <button
                      onClick={() => toggleGroupExpansion(group.title)}
                      className="mr-2 focus:outline-none"
                    >
                      <ChevronRight
                        className={`h-4 w-4 transition-transform ${
                          expandedGroups.has(group.title) ? 'transform rotate-90' : ''
                        }`}
                      />
                    </button>
                    <h3 className="text-sm font-semibold">{group.title}</h3>
                    <span className="ml-2 text-xs text-gray-400">
                      ({group.conversations.length})
                    </span>
                  </div>
                  
                  {isMultiDeleteMode && (
                    <button
                      onClick={() => toggleGroupSelection(group.conversations)}
                      className="flex items-center text-xs text-gray-400 hover:text-gray-300"
                    >
                      {group.conversations.every(conv => 
                        selectedConversations.has(conv._id)
                      ) ? (
                        <CheckSquare className="h-3 w-3 mr-1 text-blue-500" />
                      ) : (
                        <Square className="h-3 w-3 mr-1" />
                      )}
                      Select All
                    </button>
                  )}
                </div>
              )}
              
              <div className={`space-y-1 ${
                !isDesktopSidebarCollapsed && !expandedGroups.has(group.title) ? 'hidden' : ''
              }`}>
                {group.conversations.map((conv) => (
                  <div
                    key={conv._id}
                    data-conversation-id={conv._id}
                    className={`w-full flex items-center ${
                      isDesktopSidebarCollapsed ? 'px-2' : 'px-4'
                    } py-2 rounded-md group relative transition-colors ${
                      currentConversationId === conv._id 
                        ? 'bg-gray-700 dark:bg-gray-600' 
                        : 'hover:bg-gray-800 dark:hover:bg-gray-700'
                    }`}
                  >
                    {isMultiDeleteMode && !isDesktopSidebarCollapsed && (
                      <button
                        onClick={(e) => toggleConversationSelection(conv._id, e)}
                        className="p-1 hover:bg-gray-700 rounded-md mr-2"
                      >
                        {selectedConversations.has(conv._id) ? (
                          <CheckSquare className="h-4 w-4 text-blue-500" />
                        ) : (
                          <Square className="h-4 w-4 text-gray-400" />
                        )}
                      </button>
                    )}
                    
                    <div 
                      className="flex items-center min-w-0 flex-1 cursor-pointer"
                      onClick={() => !isMultiDeleteMode && loadConversation(conv._id)}
                    >
                      <FileText className={`h-4 w-4 ${!isDesktopSidebarCollapsed && 'mr-2'} flex-shrink-0 ${
                        currentConversationId === conv._id 
                          ? 'text-blue-400'
                          : ''
                      }`} />
                      {!isDesktopSidebarCollapsed && (
                        <span className={`truncate text-sm ${
                          currentConversationId === conv._id 
                            ? 'font-medium'
                            : ''
                        }`}>
                          {conv.title || 'Untitled Conversation'}
                        </span>
                      )}
                    </div>

                    {/* Add Share Button */}
                    {!isMultiDeleteMode && !isDesktopSidebarCollapsed && (
                      <button
                        onClick={(e) => handleShare(conv._id, e)}
                        className="opacity-0 group-hover:opacity-100 p-1.5 hover:bg-gray-700 rounded-md transition-all duration-200 ml-2"
                        title="Share conversation"
                      >
                        <Share2 className="h-4 w-4 text-gray-400 hover:text-gray-200" />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
          
          {/* Load More Button */}
          {hasMore && (
            <div className="py-2 px-4">
              <button
                onClick={handleLoadMore}
                disabled={isLoadingMore}
                className={`w-full py-2 px-4 text-sm rounded-md transition-colors
                  ${isLoadingMore 
                    ? 'bg-gray-600 cursor-not-allowed' 
                    : 'bg-gray-700 hover:bg-gray-600'
                  } text-gray-200`}
              >
                {isLoadingMore ? (
                  <div className="flex items-center justify-center">
                    <div className="animate-spin mr-2 h-4 w-4 border-2 border-gray-300 border-t-transparent rounded-full" />
                    Loading...
                  </div>
                ) : (
                  'Load More'
                )}
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Compact Bottom Section */}
      <div className="flex-shrink-0 border-t border-gray-600">
        {/* Combine Case Manager and Plan into one row */}
        {!isDesktopSidebarCollapsed ? (
          <div className="p-3 flex items-center gap-2">
            <div
              className={`flex-1 bg-gray-800/80 text-white px-3 py-1.5 rounded-md flex items-center relative border border-gray-600 ${
                isProduction ? 'opacity-50' : 'hover:bg-gray-700'
              }`}
            >
              <CaseManagerLink />
            </div>
            
            <div className="flex items-center gap-2 bg-gray-800/80 px-3 py-1.5 rounded-md border border-gray-600">
              <Crown className="h-4 w-4 text-yellow-500" />
              <span className={`text-xs px-2 py-0.5 rounded-full ${
                user?.plan?.type === 'Professional' 
                  ? 'bg-blue-500 text-white'
                  : user?.plan?.type === 'Enterprise'
                  ? 'bg-purple-500 text-white'
                  : user?.plan?.type === 'Essential'
                  ? 'bg-green-500 text-white'
                  : 'bg-gray-600 text-white'
              }`}>
                {user?.plan?.type || 'Trial'}
              </span>
            </div>
          </div>
        ) : (
          <div className="p-2 border-b border-gray-600">
            <div
              className={`w-full flex justify-center p-2 rounded-md border border-gray-600 ${
                isProduction ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700 cursor-pointer'
              }`}
            >
              {isProduction ? (
                <Briefcase className="h-4 w-4" />
              ) : (
                <Link to="/case-manager" className="w-full flex justify-center">
                  <Briefcase className="h-4 w-4" />
                </Link>
              )}
            </div>
          </div>
        )}

        {/* Compact Profile and Logout */}
        <div className="border-t border-gray-600">
          <div className="p-3 flex items-center gap-2">
            <Link 
              to="/profile" 
              className="flex-1 flex items-center gap-2 hover:bg-gray-800 p-1.5 rounded-md transition-colors border border-gray-600"
            >
              <div className="w-8 h-8 rounded-full bg-gray-700/80 flex items-center justify-center text-sm border border-gray-500">
                {(user?.firstName?.[0] || user?.username?.[0] || 'U').toUpperCase()}
              </div>
              {!isDesktopSidebarCollapsed && (
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-200 truncate">
                    {user?.firstName && user?.lastName 
                      ? `${user.firstName} ${user.lastName}`
                      : user?.username || 'User'}
                  </p>
                  <p className="text-xs text-gray-400 truncate">{user?.email}</p>
                </div>
              )}
            </Link>
            
            <div className="border-l border-gray-600 pl-2">
              <button
                onClick={() => setShowLogoutModal(true)}
                className="p-2 bg-red-500/10 hover:bg-red-500/20 rounded-md transition-colors flex items-center justify-center border border-gray-600"
                title="Logout"
              >
                <LogOut className="h-4 w-4 text-red-400 hover:text-red-300" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </aside>

    {/* Add the delete confirmation modal */}
    {showDeleteModal && <DeleteConfirmationModal />}
    {showLogoutModal && <LogoutConfirmationModal />}
    {showShareModal && <ShareModal />}
    </>
  );
};

export default ChatSidebar;