import axios from 'axios';
import { config } from '../config';

const cache = new Map();

const api = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

api.interceptors.request.use(async config => {
  try {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    
    // Always include token if available, regardless of endpoint
    if (auth.token) {
      config.headers.Authorization = `Bearer ${auth.token}`;
    }

    // Special handling for payment verification
    if (config.url?.includes('/payments/verify/')) {
      console.log('Payment verification request:', {
        url: config.url,
        hasToken: !!auth.token
      });
    }

    const cacheKey = `${config.method}:${config.url}`;
    
    if (config.method === 'get') {
      const cachedResponse = cache.get(cacheKey);
      if (cachedResponse) {
        const { data, timestamp } = cachedResponse;
        if (Date.now() - timestamp < 5 * 60 * 1000) {
          return {
            ...config,
            cached: true,
            data
          };
        }
        cache.delete(cacheKey);
      }
    }
    return config;
  } catch (error) {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
}, error => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  response => {
    if (!response.config.cached) {
      const cacheKey = `${response.config.method}:${response.config.url}`;
      cache.set(cacheKey, {
        data: response.data,
        timestamp: Date.now()
      });
    }
    return response;
  },
  error => {
    console.error('API Error:', {
      url: error.config?.url,
      status: error.response?.status,
      message: error.message
    });

    if (error.response?.status === 401) {
      // Handle unauthorized error
      if (error.config?.url?.includes('/payments/verify/')) {
        console.error('Unauthorized during payment verification');
      }
    }
    return Promise.reject(error);
  }
);

export default api; 