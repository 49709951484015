import React from 'react';
import { Menu, PlusCircle, Moon, Sun, HelpCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import AnimatedCounter from '../AnimatedCounter';
import { toast } from 'react-hot-toast';

const ChatHeader = ({ 
  credits, 
  isDarkMode, 
  toggleTheme, 
  setIsSidebarOpen 
}) => {
  const navigate = useNavigate();

  const handleSupportClick = (e) => {
    e.preventDefault();
    // Define popup window features
    const width = 400;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    
    // Open popup window
    const popup = window.open(
      'https://tawk.to/chat/674835da4304e3196ae9c545/1idp14o54',
      'Support Chat',
      `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes,status=no,location=no,menubar=no,toolbar=no`
    );

    // Ensure popup wasn't blocked
    if (popup && !popup.closed) {
      popup.focus();
    } else {
      toast.error('Please allow popups to open the support chat');
    }
  };

  return (
    <header className="bg-gray-200 dark:bg-gray-800 shadow-sm p-3 sm:p-4 flex items-center sticky top-0 z-10">
      {/* Left side - Menu button (mobile only) */}
      <div className="lg:hidden flex-shrink-0 w-[80px] sm:w-[100px]">
        <button
          className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
          onClick={() => setIsSidebarOpen(true)}
          aria-label="Open sidebar"
        >
          <Menu className="h-5 w-5 sm:h-6 sm:w-6 dark:text-gray-200" />
        </button>
      </div>

      {/* Center - Credits Display */}
      <div className="flex-1 flex justify-center items-center">
        <div className="flex items-center justify-center 
          bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white 
          px-2 sm:px-4 py-1.5 sm:py-2 rounded-lg shadow-sm 
          space-x-2 sm:space-x-3 
          border border-gray-200 dark:border-gray-600
          max-w-[180px] sm:max-w-none
          transform -translate-y-[1px]"
        >
          <span className="hidden xs:inline text-xs sm:text-sm font-medium uppercase tracking-wider whitespace-nowrap">
            Credits
          </span>
          <div className={`${credits < 100 ? 'text-red-500' : 'text-green-500'} 
            font-bold text-base sm:text-lg flex items-center whitespace-nowrap`}
          >
            <AnimatedCounter value={credits} />
            <button
              onClick={() => navigate('/profile?tab=subscription')}
              className="ml-1 sm:ml-2 hover:bg-gray-200 dark:hover:bg-gray-600 
                p-1 rounded-full transition-colors"
              title="Add more credits"
            >
              <PlusCircle className="h-3 w-3 sm:h-4 sm:w-4 text-blue-500" />
            </button>
          </div>
        </div>
      </div>

      {/* Right side - Theme Toggle */}
      <div className="flex-shrink-0 w-[80px] sm:w-[100px] flex justify-end">
        <button
          onClick={toggleTheme}
          className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 
            transition-colors flex items-center space-x-1 sm:space-x-2"
          aria-label="Toggle theme"
        >
          {isDarkMode ? (
            <>
              <Sun className="h-5 w-5 text-yellow-500" />
              <span className="hidden sm:inline text-sm text-gray-600 dark:text-gray-300">
                Light
              </span>
            </>
          ) : (
            <>
              <Moon className="h-5 w-5 text-gray-600" />
              <span className="hidden sm:inline text-sm text-gray-600 dark:text-gray-300">
                Dark
              </span>
            </>
          )}
        </button>
      </div>

      <div className="flex items-center space-x-4">
        {/* Help Menu */}
        <div className="relative group">
          <button
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
            aria-label="Help Menu"
          >
            <HelpCircle className="h-5 w-5 text-gray-600 dark:text-gray-300" />
          </button>
          
          {/* Dropdown Menu */}
          <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg border dark:border-gray-700 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50">
            <div className="py-1">
              <a
                href="https://chat.whatsapp.com/G0sBmOXPzbLLe9PoRtqJpo"
                className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Community
              </a>
              <button
                onClick={handleSupportClick}
                className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Chat with Support
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default ChatHeader; 