import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateProfileSuccess } from '../store/authSlice';
import { toast } from 'react-toastify';
import api from '../services/api';

const PaymentCallback = () => {
  const [paymentStatus, setPaymentStatus] = useState('processing');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const verifyPayment = async () => {
      const params = new URLSearchParams(location.search);
      const reference = params.get('reference');

      if (!reference) {
        console.error('Payment Verification Error: No reference found in URL');
        setError('No payment reference found');
        setPaymentStatus('failed');
        return;
      }

      try {
        console.log('Starting payment verification:', { reference });
        const { data } = await api.get(`/api/payments/verify/${reference}`);
        
        if (data.success) {
          console.log('Payment verification successful:', {
            plan: data.plan,
            credits: data.credits,
            creditsExpires: data.creditsExpires
          });

          dispatch(updateProfileSuccess({
            plan: data.plan,
            credits: data.credits,
            creditsExpires: data.creditsExpires
          }));
          
          setPaymentStatus('success');
          toast.success('Payment processed successfully!');
          
          setTimeout(() => {
            navigate('/profile?tab=subscription');
          }, 2000);
        } else {
          throw new Error(data.error || 'Payment verification failed');
        }
      } catch (error) {
        console.error('Payment verification error:', {
          message: error.message,
          response: error.response?.data,
          reference
        });

        const errorMessage = error.response?.data?.error || error.message;
        setError(errorMessage);
        setPaymentStatus('failed');
        toast.error('Payment verification failed');
      }
    };

    verifyPayment();
  }, [location, navigate, dispatch]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        {paymentStatus === 'processing' && (
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
            <p className="mt-4 text-lg">Verifying your payment...</p>
          </div>
        )}
        
        {paymentStatus === 'success' && (
          <div className="text-center text-green-600">
            <svg className="w-16 h-16 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            <h2 className="mt-4 text-xl font-bold">Payment Successful!</h2>
            <p className="mt-2">Your account has been updated.</p>
            <p className="mt-2 text-sm text-gray-500">Redirecting to profile...</p>
          </div>
        )}
        
        {paymentStatus === 'failed' && (
          <div className="text-center text-red-600">
            <svg className="w-16 h-16 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            <h2 className="mt-4 text-xl font-bold">Payment Failed</h2>
            {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
            <p className="mt-2">Please try again or contact support.</p>
            <div className="mt-6 space-y-3">
              <button
                onClick={() => navigate('/profile?tab=subscription')}
                className="w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Go to Profile
              </button>
              <button
                onClick={() => window.location.href = 'mailto:support@modulaw.ai'}
                className="w-full px-4 py-2 border border-gray-300 text-gray-700 rounded hover:bg-gray-50"
              >
                Contact Support
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentCallback;