import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FileText } from 'lucide-react';
import MarkdownMessage from './MarkdownMessage';
import { Avatar } from './chatmodules';
import Logo from './Logo';
import Navbar from './Navbar';
import api from '../services/api';

const PublicChat = () => {
  const { shareId } = useParams();
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const chatAreaRef = React.useRef(null);

  // Get the first message content for meta description (limited to 155 chars)
  const getMetaDescription = () => {
    if (!messages.length) return 'View this shared conversation from ModulawAI';
    const firstMessage = messages[0].content;
    return firstMessage.length > 155 
      ? `${firstMessage.substring(0, 152)}...` 
      : firstMessage;
  };

  // Get the canonical URL
  const getCanonicalUrl = () => {
    return `${window.location.origin}/share/${shareId}`;
  };

  // Schema.org JSON-LD data
  const getSchemaOrgData = () => {
    return {
      "@context": "https://schema.org",
      "@type": "Conversation",
      "url": getCanonicalUrl(),
      "numberOfMessages": messages.length,
      "provider": {
        "@type": "Organization",
        "name": "ModulawAI",
        "url": "https://modulaw.ai"
      }
    };
  };

  useEffect(() => {
    const fetchSharedConversation = async () => {
      try {
        const response = await api.get(`/conversations/share/${shareId}`);
        
        if (!response.data.messages || !Array.isArray(response.data.messages)) {
          throw new Error('Invalid conversation data');
        }
        
        setMessages(response.data.messages);
      } catch (err) {
        console.error('Error fetching shared conversation:', err);
        const errorMessage = err.response?.data?.error || err.message || 'Failed to load conversation';
        setError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    if (shareId) {
      fetchSharedConversation();
    }
  }, [shareId]);

  if (isLoading) {
    return (
      <>
        <Navbar />
        <div className="flex h-screen items-center justify-center bg-gray-100 dark:bg-gray-900 pt-20">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Navbar />
        <div className="flex h-screen items-center justify-center bg-gray-100 dark:bg-gray-900 pt-20">
          <div className="text-center p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
            <FileText className="mx-auto h-12 w-12 text-gray-400 mb-4" />
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
              Conversation Not Available
            </h2>
            <p className="text-gray-600 dark:text-gray-300">{error}</p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>Shared Conversation | ModulawAI</title>
        <meta name="description" content={getMetaDescription()} />
        <link rel="canonical" href={getCanonicalUrl()} />

        {/* OpenGraph Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Shared Conversation | ModulawAI" />
        <meta property="og:description" content={getMetaDescription()} />
        <meta property="og:url" content={getCanonicalUrl()} />
        <meta property="og:site_name" content="ModulawAI" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Shared Conversation | ModulawAI" />
        <meta name="twitter:description" content={getMetaDescription()} />

        {/* Schema.org JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify(getSchemaOrgData())}
        </script>
      </Helmet>

      <Navbar />
      <div className="flex flex-col h-screen bg-gray-100 dark:bg-gray-900 pt-20">
        {/* Header */}
        <header className="bg-white dark:bg-gray-800 border-b dark:border-gray-700 p-4 flex items-center justify-between">
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
            Shared Conversation
          </h1>
        </header>

        {/* Chat Area */}
        <div 
          className="flex-1 overflow-y-auto p-4 bg-gray-100 dark:bg-gray-900 scrollbar-thin scrollbar-thumb-gray-600 dark:scrollbar-thumb-gray-400 scrollbar-track-transparent" 
          ref={chatAreaRef}
        >
          <div className="space-y-4">
            {messages.map((msg, index) => (
              <div
                key={`${msg.role}-${index}`}
                className={`flex items-start space-x-2 ${
                  msg.role === 'user' ? 'flex-row-reverse space-x-reverse' : 'flex-row'
                }`}
              >
                <Avatar role={msg.role} />
                <div
                  className={`max-w-[85%] sm:max-w-[80%] md:max-w-[75%] rounded-lg p-3 sm:p-4 ${
                    msg.role === 'user'
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-200 dark:bg-gray-700'
                  }`}
                >
                  <p className={`text-sm font-semibold mb-1 ${
                    msg.role === 'assistant' ? 'text-gray-900 dark:text-white' : ''
                  }`}>
                    {msg.role === 'user' ? 'User' : 'ModulawAI'}
                  </p>
                  <div className={`${
                    msg.role === 'assistant' 
                      ? 'whitespace-normal text-gray-900 dark:text-white' 
                      : 'whitespace-pre-wrap'
                  }`}>
                    {msg.role === 'assistant' ? (
                      <MarkdownMessage content={msg.content} />
                    ) : (
                      msg.content
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Footer */}
        <footer className="bg-gray-200 dark:bg-gray-800 border-t dark:border-gray-700 p-3">
          <p className="text-xs text-center text-gray-500 dark:text-gray-400">
            This is a shared conversation from ModulawAI. Visit{' '}
            <a 
              href="https://modulaw.ai" 
              className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
              target="_blank" 
              rel="noopener noreferrer"
            >
              modulaw.ai
            </a>
            {' '}to start your own conversation.
          </p>
        </footer>
      </div>
    </>
  );
};

export default PublicChat; 