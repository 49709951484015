import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import api from '../../services/api';
import CreditsHistory from './CreditsHistory';

const ITEMS_PER_PAGE = 10;

const CreditsHistoryContainer = () => {
  const [creditsHistory, setCreditsHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCreditsHistory = async () => {
      try {
        setIsLoading(true);
        const response = await api.get('/api/user/credits-history');
        setCreditsHistory(response.data.creditsHistory);
      } catch (error) {
        console.error('Failed to fetch credits history:', error);
        toast.error('Failed to load credits history');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCreditsHistory();
  }, []);

  if (isLoading) {
    return <div className="flex justify-center py-4">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
    </div>;
  }

  return (
    <CreditsHistory 
      creditHistory={creditsHistory.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE)} 
      currentPage={currentPage}
      totalPages={Math.ceil(creditsHistory.length / ITEMS_PER_PAGE)}
      onPageChange={setCurrentPage}
    />
  );
};

export default CreditsHistoryContainer; 