import React, { useState, useEffect, useCallback } from 'react';
import { RefreshCw } from 'lucide-react';

const allTemplateMessages = [
  // Judicial Precedents & Case Law
  "What are the leading Supreme Court authorities on the doctrine of legitimate expectation in administrative law?",
  "Analyze the ratio decidendi in Kotoye v. Saraki on the principles of interlocutory injunctions.",
  "What is the current judicial position on the application of nemo judex in causa sua in administrative tribunals?",
  "Examine the Supreme Court's interpretation of Section 6(6)(b) of the 1999 Constitution regarding justiciability.",
  "What are the landmark cases establishing the principles of locus standi in public interest litigation?",
  "Analyze recent Court of Appeal decisions on the application of res ipsa loquitur in medical negligence cases.",
  "What is the judicial interpretation of 'fundamental breach' in contractual disputes post Kano State v. Fagge?",
  "Examine the evolution of the blue pencil doctrine in restraint of trade cases in Nigerian jurisprudence.",
  "What are the leading authorities on the application of the cy-près doctrine in charitable trusts?",
  "Analyze recent Supreme Court decisions on the scope of Order 6 Rule 1 of the Federal High Court Rules.",

  // Constitutional Law & Interpretation
  "What is the current judicial interpretation of Section 308 immunity clause post-Fawehinmi v. IGP?",
  "Examine the constitutional basis for the doctrine of covering the field in Nigerian federalism.",
  "What are the leading cases on the interpretation of Section 251 of the 1999 Constitution?",
  "Analyze judicial authorities on the doctrine of political question in Nigerian constitutional law.",
  "What is the scope of executive privilege under Nigerian constitutional jurisprudence?",
  "Examine recent judicial pronouncements on the doctrine of ripeness in constitutional litigation.",
  "What are the leading authorities on the interpretation of Section 162 on revenue allocation?",
  "Analyze the judicial approach to interpreting fundamental rights provisions post-Fundamental Rights (Enforcement Procedure) Rules 2009.",
  "What is the current position on the doctrine of prospective overruling in constitutional matters?",
  "Examine the evolution of the political thresholds doctrine in election petition cases.",

  // Civil Procedure & Evidence
  "What are the recent judicial authorities on the application of the slip rule under Order 45?",
  "Analyze the current position on electronic evidence authentication post-Evidence Act 2011.",
  "What is the scope of judicial notice in modern Nigerian evidence law?",
  "Examine recent decisions on the application of Anton Piller orders in digital forensics cases.",
  "What are the leading authorities on similar fact evidence in civil proceedings?",
  "Analyze judicial interpretation of Order 29 on proceedings against the government.",
  "What is the current position on the admissibility of computer-generated evidence?",
  "Examine recent decisions on the application of res judicata in administrative proceedings.",
  "What are the authorities on the scope of Norwich Pharmacal orders in Nigerian law?",
  "Analyze judicial approach to Mareva injunctions in cryptocurrency disputes.",

  // Commercial & Corporate Law
  "What is the judicial interpretation of 'shadow director' under CAMA 2020?",
  "Examine recent decisions on lifting the corporate veil in group company structures.",
  "What are the leading authorities on minority shareholder protection under CAMA 2020?",
  "Analyze judicial approach to interpreting Rule 42 of the SEC Rules on takeovers.",
  "What is the current position on the doctrine of ultra vires post-CAMA 2020?",
  "Examine judicial interpretation of Section 867 on schemes of arrangement.",
  "What are the authorities on directors' fiduciary duties in corporate restructuring?",
  "Analyze recent decisions on the application of the business judgment rule.",
  "What is the judicial approach to interpreting shareholder agreements?",
  "Examine recent cases on corporate rescue mechanisms under CAMA 2020.",

  // Administrative & Public Law
  "What are the leading cases on the doctrine of substantive legitimate expectation?",
  "Analyze judicial review principles post-Inakoju v. Adeleke.",
  "What is the current position on ouster clauses in administrative law?",
  "Examine recent decisions on the scope of ministerial discretion.",
  "What are the authorities on the doctrine of proportionality in judicial review?",
  "Analyze judicial approach to error of law on the face of the record.",
  "What is the scope of certiorari in modern administrative law?",
  "Examine recent cases on the doctrine of procedural ultra vires.",
  "What are the leading authorities on bias in administrative proceedings?",
  "Analyze judicial interpretation of statutory consultation requirements.",

  // Alternative Dispute Resolution
  "What is the judicial approach to enforcing multi-tiered dispute resolution clauses?",
  "Examine recent decisions on the scope of arbitrability under Nigerian law.",
  "What are the authorities on the doctrine of separability in arbitration?",
  "Analyze judicial interpretation of the New York Convention provisions.",
  "What is the current position on kompetenz-kompetenz in Nigerian arbitration?",
  "Examine recent cases on the enforcement of foreign arbitral awards.",
  "What are the leading authorities on the scope of public policy in arbitration?",
  "Analyze judicial approach to interim measures in international arbitration.",
  "What is the position on confidentiality in arbitration proceedings?",
  "Examine recent decisions on the scope of arbitrators' jurisdiction.",

  // Criminal Law & Procedure
  "What are the leading authorities on mens rea in regulatory offenses?",
  "Analyze recent decisions on the application of the rule in Woolmington v DPP.",
  "What is the current position on digital evidence in criminal proceedings?",
  "Examine judicial interpretation of Section 36(12) in criminal matters.",
  "What are the authorities on circumstantial evidence in criminal cases?",
  "Analyze judicial approach to confessional statements post-ACJA 2015.",
  "What is the scope of plea bargaining under current Nigerian law?",
  "Examine recent cases on the doctrine of double jeopardy.",
  "What are the leading authorities on proof beyond reasonable doubt?",
  "Analyze judicial interpretation of alibi defense requirements.",

  // Specialized Areas
  "What is the judicial interpretation of 'fair dealing' under copyright law?",
  "Examine recent decisions on maritime liens and ship arrests.",
  "What are the authorities on environmental impact assessments?",
  "Analyze judicial approach to petroleum profit tax disputes.",
  "What is the current position on domain name disputes?",
  "Examine recent cases on banking confidentiality post-CBN Act.",
  "What are the leading authorities on telecommunications licensing?",
  "Analyze judicial interpretation of competition law provisions.",
  "What is the position on force majeure in energy contracts?",
  "Examine recent decisions on Islamic banking regulations."
];

const TemplateMessages = ({ onSelectTemplate }) => {
  const [templateMessages, setTemplateMessages] = useState([]);
  const [currentSet, setCurrentSet] = useState(0);

  const getRandomTemplates = useCallback(() => {
    const shuffled = [...allTemplateMessages].sort(() => 0.5 - Math.random());
    const sets = [];
    for (let i = 0; i < shuffled.length; i += 4) {
      sets.push(shuffled.slice(i, i + 4));
    }
    return sets;
  }, []);

  const [templateSets, setTemplateSets] = useState([]);

  useEffect(() => {
    const sets = getRandomTemplates();
    setTemplateSets(sets);
    setTemplateMessages(sets[0]);
  }, [getRandomTemplates]);

  const handleMoreTemplates = () => {
    const nextSet = (currentSet + 1) % templateSets.length;
    setCurrentSet(nextSet);
    setTemplateMessages(templateSets[nextSet]);
  };

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <h2 className="text-2xl font-bold mb-4 dark:text-white">Start a new conversation</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 w-full max-w-3xl mx-auto px-4">
        {templateMessages.map((template, index) => (
          <button
            key={`${currentSet}-${index}`}
            className="p-4 bg-white dark:bg-gray-800 shadow-sm rounded-lg 
                     hover:bg-gray-50 dark:hover:bg-gray-700 text-left dark:text-white
                     transition-all duration-200 ease-in-out
                     hover:shadow-md"
            onClick={() => onSelectTemplate(template)}
          >
            {template}
          </button>
        ))}
      </div>
      
      <button
        onClick={handleMoreTemplates}
        className="mt-6 flex items-center gap-2 px-4 py-2 
                 bg-blue-600 hover:bg-blue-700 
                 text-white rounded-lg transition-colors
                 shadow-sm hover:shadow-md"
      >
        <RefreshCw className="h-4 w-4" />
        <span>More Templates</span>
      </button>
    </div>
  );
};

export default TemplateMessages; 