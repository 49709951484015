import React from 'react';

const Logo = ({ 
  className = '', 
  width = '150', 
  height = '150', 
  isDark = false
}) => {
  // Simply use the file name that matches the mode
  const logoSrc = isDark 
    ? '/Modulaw-Logo-light.svg'  // Light (white) logo for dark mode
    : '/Modulaw-Logo-dark.svg';  // Dark logo for light mode

  return (
    <img
      src={logoSrc}
      alt="Modulaw AI Logo"
      className={className}
      width={width}
      height={height}
      onError={(e) => {
        console.error('Logo failed to load:', e);
        e.target.style.display = 'none';
      }}
    />
  );
};

export default Logo;