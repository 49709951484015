import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../store/authSlice';
import { Crown, Mail, Plus, UserCircle} from 'lucide-react';
import toast, { Toaster } from 'react-hot-toast';
import PricingModal from './PricingModal';
import api from '../services/api';
import Navbar from './Navbar';
import WorkspaceCard from './workspace/WorkspaceCard';
import BasicInfoForm from './profile/BasicInfoForm';
import PasswordForm from './profile/PasswordForm';
import DangerZone from './profile/DangerZone';
import CurrentPlan from './subscription/CurrentPlan';
import WorkspaceStats from './workspace/WorkspaceStats';
import InvitationModal from './workspace/InvitationModal';
import UserWorkspaceView from './workspace/UserWorkspaceView';
import PaymentHistoryContainer from './transactions/PaymentHistoryContainer';
import CreditsHistoryContainer from './transactions/CreditsHistoryContainer';

const Profile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  
  const [activeTab, setActiveTab] = useState('basic');
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [activeTransactionTab, setActiveTransactionTab] = useState('payments');

  // Form states
  const [basicInfo, setBasicInfo] = useState(() => ({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phoneNumber: user?.phoneNumber || '',
    bio: user?.bio || '',
    firmName: user?.firmName || ''
  }));
  
  const [passwordInfo, setPasswordInfo] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  
  const [workspaces, setWorkspaces] = useState([]);
  const [newWorkspaceName, setNewWorkspaceName] = useState('');
  const [workspaceDescription, setWorkspaceDescription] = useState('');
  const [editingWorkspace, setEditingWorkspace] = useState(null);
  const [editWorkspaceName, setEditWorkspaceName] = useState('');
  const [editWorkspaceDescription, setEditWorkspaceDescription] = useState('');
  const [showMemberResults, setShowMemberResults] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');
    if (tab && ['basic', 'subscription', 'transactions'].includes(tab)) {
      setActiveTab(tab);
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'workspace') {
      fetchWorkspaces();
    }
  }, [activeTab]);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        setIsLoading(true);
        const response = await api.get('/api/user/subscription');
        setSubscriptionData(response.data);
      } catch (error) {
        console.error('Failed to fetch subscription data:', error);
        toast.error('Failed to load subscription information');
      } finally {
        setIsLoading(false);
      }
    };

    if (activeTab === 'subscription') {
      fetchSubscriptionData();
    }
  }, [activeTab]);

  useEffect(() => {
    if (workspaces.length > 0 && !selectedWorkspaceId) {
      setSelectedWorkspaceId(workspaces[0]._id);
    }
  }, [workspaces]);

  const fetchWorkspaces = async () => {
    try {
      const response = await api.get('/api/workspaces');
      setWorkspaces(response.data.workspaces || []);
    } catch (error) {
      console.error('Failed to fetch workspaces:', error);
      toast.error('Failed to fetch workspaces');
    }
  };

  // Handle basic info update
  const handleBasicInfoUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Add firmName to the updatedInfo payload
      const updatedInfo = {
        firstName: basicInfo.firstName.trim(),
        lastName: basicInfo.lastName.trim(),
        phoneNumber: basicInfo.phoneNumber.trim(),
        bio: basicInfo.bio.trim(),
        firmName: basicInfo.firmName.trim()
      };
      
      await dispatch(updateProfile(updatedInfo)).unwrap();
      toast.success('Profile updated successfully');
    } catch (error) {
      toast.error(error.message || 'Failed to update profile');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle password change
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (passwordInfo.newPassword !== passwordInfo.confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    
    setIsLoading(true);
    try {
      await api.post('/api/user/change-password', {
        currentPassword: passwordInfo.currentPassword,
        newPassword: passwordInfo.newPassword
      });
      toast.success('Password updated successfully');
      setPasswordInfo({ currentPassword: '', newPassword: '', confirmPassword: '' });
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to update password');
    } finally {
      setIsLoading(false);
    }
  };

  // Workspace management functions
  const handleCreateWorkspace = async (e) => {
    e.preventDefault();
    try {
      await api.post('/api/workspaces', {
        name: newWorkspaceName,
        description: workspaceDescription
      });
      toast.success('Workspace created successfully');
      fetchWorkspaces();
      setNewWorkspaceName('');
      setWorkspaceDescription('');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to create workspace');
    }
  };

  const handleEditWorkspace = (workspace) => {
    setEditingWorkspace(workspace._id);
    setEditWorkspaceName(workspace.name);
    setEditWorkspaceDescription(workspace.description || '');
  };

  const handleSaveWorkspaceEdit = async (workspaceId) => {
    try {
      await api.put(`/api/workspaces/${workspaceId}`, {
        name: editWorkspaceName,
        description: editWorkspaceDescription
      });
      toast.success('Workspace updated successfully');
      fetchWorkspaces();
      setEditingWorkspace(null);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to update workspace');
    }
  };

  const handleDeleteWorkspace = async (workspaceId) => {
    if (window.confirm('Are you sure you want to delete this workspace?')) {
      try {
        await api.delete(`/api/workspaces/${workspaceId}`);
        toast.success('Workspace deleted successfully');
        fetchWorkspaces();
      } catch (error) {
        toast.error(error.response?.data?.message || 'Failed to delete workspace');
      }
    }
  };

  const handleUpgradeClick = () => {
    setIsPricingModalOpen(true);
  };

  // Add this function to check if user can create workspaces
  const canCreateWorkspace = () => {
    // Allow workspace creation if:
    // 1. User has no workspaces
    // 2. User is a SUPER_ADMIN in any workspace
    return !workspaces || workspaces.length === 0 || workspaces.some(workspace => 
      workspace.members?.some(member => 
        member.user._id === user._id && member.role === 'SUPER_ADMIN'
      ) || workspace.owner._id === user._id
    );
  };

  // Add helper function to check if user is admin
  const isAdminUser = (workspace, userId) => {
    const userMember = workspace?.members?.find(member => member.user._id === userId);
    return userMember?.role === 'SUPER_ADMIN' || 
           userMember?.role === 'ORGANIZATION_ADMIN' || 
           userMember?.role === 'CASE_MANAGER';
  };

  // Update the renderWorkspaceContent function
  const renderWorkspaceContent = () => {
    const selectedWorkspace = workspaces.find(w => w._id === selectedWorkspaceId);
    const isAdmin = selectedWorkspace && isAdminUser(selectedWorkspace, user._id);

    // Show create workspace form for new users or SUPER_ADMINs
    if (!selectedWorkspace) {
      return (
        <div className="space-y-4 sm:space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Always show create workspace form if user can create one */}
            {canCreateWorkspace() && (
              <div className="bg-white dark:bg-gray-800 shadow-sm rounded-lg p-3 sm:p-6">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                  Create New Workspace
                </h3>
                <form onSubmit={handleCreateWorkspace} className="space-y-4">
                  <input
                    type="text"
                    value={newWorkspaceName}
                    onChange={(e) => setNewWorkspaceName(e.target.value)}
                    placeholder="Think of a department name as workspace name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    required
                  />
                  <textarea
                    value={workspaceDescription}
                    onChange={(e) => setWorkspaceDescription(e.target.value)}
                    placeholder="Description"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    rows={3}
                  />
                  <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Create Workspace
                  </button>
                </form>
              </div>
            )}
            
            {/* Show message if no workspace is selected */}
            <div className="text-center text-gray-500 dark:text-gray-400 py-8">
              No workspace selected
            </div>
          </div>
        </div>
      );
    }

    if (!isAdmin) {
      return <UserWorkspaceView workspace={selectedWorkspace} />;
    }

    return (
      <div className="space-y-4 sm:space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {canCreateWorkspace() && (
            <div className="bg-white dark:bg-gray-800 shadow-sm rounded-lg p-3 sm:p-6">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                Create New Workspace
              </h3>
              <form onSubmit={handleCreateWorkspace} className="space-y-4">
                <input
                  type="text"
                  value={newWorkspaceName}
                  onChange={(e) => setNewWorkspaceName(e.target.value)}
                  placeholder="Think of a department name as workspace name"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  required
                />
                <textarea
                  value={workspaceDescription}
                  onChange={(e) => setWorkspaceDescription(e.target.value)}
                  placeholder="Description"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  rows={3}
                />
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Create Workspace
                </button>
              </form>
            </div>
          )}

          <div className="bg-white dark:bg-gray-800 shadow-sm rounded-lg p-3 sm:p-6">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              Select Workspace
            </h3>
            <select
              value={selectedWorkspaceId}
              onChange={(e) => setSelectedWorkspaceId(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-4"
            >
              <option value="">Select a workspace</option>
              {workspaces.map((workspace) => (
                <option key={workspace._id} value={workspace._id}>
                  {workspace.name}
                </option>
              ))}
            </select>

            {selectedWorkspaceId && (
              <WorkspaceStats 
                workspace={workspaces.find(w => w._id === selectedWorkspaceId)} 
              />
            )}
          </div>
        </div>

        {selectedWorkspaceId && workspaces.length > 0 && (
          <WorkspaceCard
            workspace={selectedWorkspace}
            editingWorkspace={editingWorkspace}
            editWorkspaceName={editWorkspaceName}
            editWorkspaceDescription={editWorkspaceDescription}
            setEditWorkspaceName={setEditWorkspaceName}
            setEditWorkspaceDescription={setEditWorkspaceDescription}
            handleSaveWorkspaceEdit={handleSaveWorkspaceEdit}
            setEditingWorkspace={setEditingWorkspace}
            handleEditWorkspace={handleEditWorkspace}
            handleDeleteWorkspace={handleDeleteWorkspace}
            isLoading={isLoading}
            selectedWorkspaceId={selectedWorkspaceId}
            onInviteClick={() => setIsInviteModalOpen(true)}
          />
        )}
      </div>
    );
  };


  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 pt-16">
        <div className="max-w-7xl mx-auto py-2 sm:py-4 lg:py-6 px-2 sm:px-4 lg:px-8">
          {/* Navigation Tabs - Adjust for mobile */}
          <div className="border-b border-gray-200 dark:border-gray-700 mb-4 sm:mb-6">
            <nav className="-mb-px flex justify-start sm:justify-center space-x-2 sm:space-x-4 lg:space-x-8 overflow-x-auto">
              {/* Tab buttons - Make them more compact on mobile */}
              <button
                onClick={() => setActiveTab('basic')}
                className={`${
                  activeTab === 'basic'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 lg:px-4 border-b-2 font-medium text-xs sm:text-sm lg:text-base transition-colors duration-200`}
              >
                <span className="flex items-center">
                  <UserCircle className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
                  <span className="truncate">Basic Info</span>
                </span>
              </button>

              <button
                onClick={() => setActiveTab('workspace')}
                className={`${
                  activeTab === 'workspace'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 lg:px-4 border-b-2 font-medium text-xs sm:text-sm lg:text-base transition-colors duration-200`}
              >
                <span className="flex items-center">
                  <Plus className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
                  <span className="truncate">Workspace</span>
                </span>
              </button>

              <button
                onClick={() => setActiveTab('subscription')}
                className={`${
                  activeTab === 'subscription'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 lg:px-4 border-b-2 font-medium text-xs sm:text-sm lg:text-base transition-colors duration-200`}
              >
                <span className="flex items-center">
                  <Crown className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
                  <span className="truncate">Subscription</span>
                </span>
              </button>

              <button
                onClick={() => setActiveTab('transactions')}
                className={`${
                  activeTab === 'transactions'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-2 sm:py-4 px-1 sm:px-2 lg:px-4 border-b-2 font-medium text-xs sm:text-sm lg:text-base transition-colors duration-200`}
              >
                <span className="flex items-center">
                  <Mail className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
                  <span className="truncate">Transactions</span>
                </span>
              </button>
            </nav>
          </div>

          {/* Content container - Add responsive padding */}
          <div className="mx-auto px-2 sm:px-4">
            {activeTab === 'basic' && (
              <div className="space-y-6 bg-white dark:bg-gray-800 shadow-sm rounded-lg p-4 sm:p-6">
                <div className="max-w-full overflow-x-hidden">
                  <BasicInfoForm
                    basicInfo={basicInfo}
                    setBasicInfo={setBasicInfo}
                    handleBasicInfoUpdate={handleBasicInfoUpdate}
                    isLoading={isLoading}
                  />
                  <PasswordForm
                    passwordInfo={passwordInfo}
                    setPasswordInfo={setPasswordInfo}
                    handlePasswordChange={handlePasswordChange}
                    isLoading={isLoading}
                  />
                  <DangerZone />
                </div>
              </div>
            )}

            {activeTab === 'workspace' && renderWorkspaceContent()}

            {activeTab === 'subscription' && (
              <div className="bg-white dark:bg-gray-800 shadow-sm rounded-lg p-3 sm:p-6 space-y-4 sm:space-y-6">
                {isLoading ? (
                  <div className="flex justify-center items-center py-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                  </div>
                ) : (
                  <CurrentPlan 
                    subscriptionData={subscriptionData} 
                    onUpgrade={handleUpgradeClick}
                    credits={user.credits?.amount || 0}
                    creditsExpiry={user.credits?.expiryDate}
                  />
                )}
              </div>
            )}

            {activeTab === 'transactions' && (
              <div className="bg-white dark:bg-gray-800 shadow-sm rounded-lg p-3 sm:p-6">
                <div className="space-y-4">
                  {/* Transaction type tabs */}
                  <div className="flex space-x-4">
                    <button
                      onClick={() => setActiveTransactionTab('payments')}
                      className={`px-4 py-2 rounded-lg ${
                        activeTransactionTab === 'payments'
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      }`}
                    >
                      Payment History
                    </button>
                    <button
                      onClick={() => setActiveTransactionTab('credits')}
                      className={`px-4 py-2 rounded-lg ${
                        activeTransactionTab === 'credits'
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      }`}
                    >
                      Credits History
                    </button>
                  </div>

                  <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                    {activeTransactionTab === 'payments' ? (
                      <PaymentHistoryContainer />
                    ) : (
                      <CreditsHistoryContainer />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {isPricingModalOpen && (
        <PricingModal 
          isOpen={isPricingModalOpen} 
          onClose={() => setIsPricingModalOpen(false)} 
        />
      )}
      {selectedWorkspaceId && (
        <InvitationModal
          isOpen={isInviteModalOpen}
          onClose={() => setIsInviteModalOpen(false)}
          workspace={workspaces.find(w => w._id === selectedWorkspaceId)}
          onInvitationComplete={fetchWorkspaces}
        />
      )}
      <Toaster position="top-right" />
    </>
  );
};

export default Profile;
