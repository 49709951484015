import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { toast } from 'react-hot-toast';
import PaymentHistory from './PaymentHistory';

const ITEMS_PER_PAGE = 10;

const PaymentHistoryContainer = () => {
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      try {
        setIsLoading(true);
        const response = await api.get('/api/user/payment-history');
        
        // Deduplicate payments based on transactionId or paymentReference
        const uniquePayments = response.data.payments.reduce((acc, payment) => {
          const key = payment.transactionId || payment.paymentReference;
          if (!acc.has(key)) {
            acc.set(key, payment);
          }
          return acc;
        }, new Map());

        const deduplicatedPayments = Array.from(uniquePayments.values());
        
        // Sort by date in descending order
        const sortedPayments = deduplicatedPayments.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );

        setPaymentHistory(sortedPayments);
      } catch (error) {
        console.error('Failed to fetch payment history:', error);
        toast.error('Failed to load payment history');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentHistory();
  }, []);

  if (isLoading) {
    return <div className="flex justify-center py-4">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
    </div>;
  }

  return (
    <PaymentHistory 
      paymentHistory={paymentHistory.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE)} 
      currentPage={currentPage}
      totalPages={Math.ceil(paymentHistory.length / ITEMS_PER_PAGE)}
      onPageChange={setCurrentPage}
    />
  );
};

export default PaymentHistoryContainer; 