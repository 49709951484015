import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Crown, ArrowRight } from 'lucide-react';

const SubscriptionExpiredBanner = ({ planType }) => {
  const navigate = useNavigate();

  const handleRenewal = () => {
    // Navigate to profile page with subscription tab
    navigate('/profile?tab=subscription');
  };

  return (
    <div className="flex-1 flex flex-col items-center justify-center p-4 sm:p-8 bg-gray-100 dark:bg-gray-900">
      <div className="max-w-md w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 space-y-6">
        <div className="text-center">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-100 dark:bg-red-900 mb-4">
            <Crown className="h-8 w-8 text-red-600 dark:text-red-400" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
            Subscription Expired
          </h2>
          <p className="text-gray-600 dark:text-gray-300 mb-6">
            Your {planType || 'current'} subscription has expired. Renew now to continue accessing all features of ModulawAI.
          </p>
        </div>

        <div className="space-y-4">
          <button
            onClick={handleRenewal}
            className="w-full flex items-center justify-center space-x-2 px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors duration-200 shadow-sm"
          >
            <span>Renew Subscription</span>
            <ArrowRight className="h-4 w-4" />
          </button>
          
          <button
            onClick={() => navigate('/profile')}
            className="w-full px-6 py-3 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white bg-gray-100 dark:bg-gray-700 rounded-lg transition-colors duration-200"
          >
            View Profile Settings
          </button>
        </div>

        <p className="text-sm text-center text-gray-500 dark:text-gray-400">
          Need help? Contact our support team
        </p>
      </div>
    </div>
  );
};

export default SubscriptionExpiredBanner; 