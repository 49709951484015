import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from './store/index.js';
import { ThemeProvider } from './providers/ThemeProvider';
import { fetchProfile, loginSuccess, fetchCredits } from './store/authSlice';
import LoginForm from './components/LoginForm.js';
import SignupForm from './components/SignupForm.js';
import PricingPage from './components/PricingPage.js';
import Profile from './components/Profile.js';
import Chat from './components/Chat.js';
import HomePage from './components/Homepage.js';
import ProtectedRoute from './components/ProtectedRoute.js';
import './App.css';
import EmailVerification from './components/EmailVerification';
import PaymentCallback from './components/PaymentCallback';
import Layout from './components/Layout';
import ContactUs from './components/ContactUs';
import CaseManager from './components/caseManager';
import AcceptInvitation from './components/workspace/AcceptInvitation';
import ResetPassword from './components/ResetPassword';
import PublicChat from './components/PublicChat';
import ErrorBoundary from './components/ErrorBoundary';
import { HelmetProvider } from 'react-helmet-async';

function AppContent() {
  const dispatch = useDispatch();
  const { token, user } = useSelector(state => state.auth);

  useEffect(() => {
    if (token) {
      Promise.all([
        !user && dispatch(fetchProfile()),
        dispatch(fetchCredits())
      ]);
    }
  }, [dispatch, token]);

  return (
    <div className="App">
      <ErrorBoundary>
        <Routes>
          <Route path="/share/:shareId" element={<PublicChat />} />
          <Route path="/chat/*" element={<Chat />} />
          <Route path="/login" element={
            <Layout>
              <LoginForm />
            </Layout>
          } />
          <Route path="/" element={
            <Layout>
              <HomePage />
            </Layout>
          } />
          <Route path="/signup" element={
            <Layout>
              <SignupForm />
            </Layout>
          } />
          <Route path="/profile" element={
            <ProtectedRoute>
              <Layout>
                <Profile />
              </Layout>
            </ProtectedRoute>
          } />
          <Route path="/pricing" element={
            <Layout>
              <PricingPage />
            </Layout>
          } />
          <Route path="/verify-email" element={
            <Layout>
              <EmailVerification />
            </Layout>
          } />
          <Route path="/emailverification" element={
            <Layout>
              <EmailVerification />
            </Layout>
          } />
          <Route path="/payment/callback" element={
            <Layout>
              <PaymentCallback />
            </Layout>
          } />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/case-manager" element={
            <ProtectedRoute>
              <Layout showNavbar={false}>
                <CaseManager />
              </Layout>
            </ProtectedRoute>
          } />
          <Route path="/workspace/join/:token" element={<AcceptInvitation />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/chat" replace />} />
        </Routes>
      </ErrorBoundary>
    </div>
  );
}

const InitializeAuth = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    try {
      const persistedAuth = localStorage.getItem('auth');
      if (persistedAuth) {
        const authData = JSON.parse(persistedAuth);
        if (authData.user && authData.token) {
          dispatch(loginSuccess(authData));
        }
      }
    } catch (error) {
      console.error('Error initializing auth:', error);
    }
  }, [dispatch]);

  return null;
};

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider>
          <BrowserRouter>
            <InitializeAuth />
            <AppContent />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
